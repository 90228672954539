import React from "react";
import { injectIntl } from "react-intl";
import ListLabelButton from "components/ListLabelButton/ListLabelButton";
import ListIconButton from "components/ListIconButton/ListIconButton";
import { getStatusColor } from "../utils";
import messages from "../../../messages";
import { getAgeFromDateString } from "../../../utils/date";

const ListLabelSporterWrapper = ({ data, onClick, intl }) => {
  const info = intl.formatMessage(messages.appDefaultCopyAgeFormat, {
    age: getAgeFromDateString(data.birthdate),
  });

  const statusLabel = `${data.completed}/${data.total}`;
  let statusColor = getStatusColor(data);
  const isCompleted = data.completed === data.total;
  return (
    <div>
      {isCompleted ? (
        <ListIconButton
          gray
          label={data.firstname}
          extraLabel={data.lastname}
          statusColor={statusColor}
          statusIcon="check"
          info={info}
          onClick={() => onClick(data.id)}
        />
      ) : (
        <ListLabelButton
          gray
          label={data.firstname}
          extraLabel={data.lastname}
          statusColor={statusColor}
          statusLabel={statusLabel}
          info={info}
          onClick={() => onClick(data.id)}
        />
      )}
    </div>
  );
};

export default injectIntl(ListLabelSporterWrapper);
