import React from 'react';
import styled from 'styled-components';
import { Box } from 'grid-styled';

const FooterWrapper = styled(Box)`
  width: 100%;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding-top: 18px;
`;

class Footer extends React.Component {
  render() {
    return (
      <FooterWrapper width="{1/1}" flex="0 0 90px" pl={30} pr={30} is="footer">
        {this.props.children}
      </FooterWrapper>
    );
  }
}

export default Footer;
