import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from 'grid-styled';

import H3 from '../H3/H3';
import P from '../P/P';

const AlertWrapper = styled.div`
  background: ${props => props.theme.colors.nightblue};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: ${props => props.theme.zIndex.alert};
  display: none;

  @media screen and (max-width: 767px) and (orientation: landscape) and (min-aspect-ratio: 13/9) {
    display: block;
  }
`;
const AlertBox = styled.div`
  width: 75%;
  height: 100vh;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const AlertBoxHeader = styled(Box)`
  display: flex;
  align-items: flex-start;

  h3 {
    flex: 1 1 auto;
    margin-right: 10px;
  }
`;

class LandscapeWarning extends React.Component {
  render() {
    const { title, message } = this.props;
    return (
      <AlertWrapper>
        <AlertBox>
          <AlertBoxHeader mb={20}>
            <H3 inverted>{title}</H3>
            {/*<CloseButton onClick={cancelHandler} />*/}
          </AlertBoxHeader>
          <Box>
            <P inverted>{message}</P>
          </Box>
        </AlertBox>
      </AlertWrapper>
    );
  }
}

LandscapeWarning.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired
};

export default LandscapeWarning;
