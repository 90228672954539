import { observable, action } from "mobx";
import { loadTestSet } from "utils/api";
import { persist } from "mobx-persist";

export default class SessionsStore {
  constructor(routerStore, uiState) {
    this.routerStore = routerStore;
    this.uiState = uiState;

    //this.loadTestSet();
  }

  @persist("list")
  @observable
  sessions = [];

  @persist("object")
  @observable
  testSetData = {};

  @action
  async loadTestSet() {
    //this.testSetData = loadTestSet();
  }

  getTestSetDataForTest(testsetId, testId) {
    try {
      const testSetData = loadTestSet();
      return {
        testsetId,
        testId,
        test: testSetData.tests[testId],
      };
    } catch (error) {
      console.error("error", error.toString());
      return null;
    }
  }
}
