import { css } from 'styled-components';

const textStyles = css`
  display: block;
  font-family: ${props => props.theme.fonts.fontfaceLight};
  line-height: 1em;
  margin: 0;
  font-size: ${props => props.theme.fonts.sizeBase};
  line-height: 18px;
  color: ${props => props.theme.colors.nightblue};

  ${props =>
    props.danger &&
    css`
      color: ${props => props.theme.colors.danger};
    `} ${props => props.textcenter && css`text-align: center;`} ${props =>
      props.inverted &&
      css`
        color: ${props => props.theme.colors.white};

        a {
          color: ${props => props.theme.colors.grey};
        }
      `} ${props => props.italic && css`font-style: italic;`} ${props =>
      props.upper && css`text-transform: uppercase;`} ${props =>
      props.bold &&
      css`
        font-family: ${props => props.theme.fonts.fontfaceBold};
      `} ${props =>
      props.small &&
      css`
        font-size: ${props => props.theme.fonts.sizeSmall};
      `} a {
    color: ${props => props.theme.colors.blue};
    text-decoration: none;
  }

  b {
    font-family: ${props => props.theme.fonts.fontfaceBold};
  }
`;

export default textStyles;
