import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_PATH;

const LOGOUT_URL = "user/logout/";
const LOGIN_URL = "user/login/";
const SESSION_URL = "session/";
const GROUP_URL = "sn_restapi_user/";
const ACTIVATE_GROUP_URL = "sn_restapi_user/switch_customer/";

const USER_NAME = "tester.han";
const USER_PASS = "tester.han";

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

let TESTS_DATA = {};

/**
 * logs out given user
 * @param {String} username
 * @param {String} password
 */
export async function logoutUser() {
  try {
    let logoutResult = await axios.post(LOGOUT_URL);
    return logoutResult;
  } catch (error) {
    throw error;
  }
}
/**
 * login user with given username and password
 * @param {String} username
 * @param {String} password
 */
export async function loginUser(username = USER_NAME, password = USER_PASS) {
  try {
    let loginResult = await axios.post(LOGIN_URL, {
      username,
      password,
    });
    return loginResult;
  } catch (error) {
    throw error;
  }
}

/**
 * load all groups/schools for the logged in user
 * we need to pass the uid of the user
 * retrieves groups/schools
 */
export async function loadUserGroups(userId) {
  try {
    const config = {
      params: {
        customers: 1,
      },
    };
    let response = await axios.get(GROUP_URL + userId, config);

    return response.data;
  } catch (error) {
    throw error;
  }
}

/**
 * need to be called before we can get alle active
 * sessions this will active the selected group
 * and after this call we can get the sessions for this group/school
 * @param {*} groupId
 */
export async function activateGroup(groupId) {
  try {
    let data = new FormData();
    data.append("tid", groupId);
    let response = await axios.post(ACTIVATE_GROUP_URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

/**
 * loads all data needed so we can select the tests
 * contains all athletes and all tests of this testset
 * alse the completed data and completed tests
 * @param {int} testSetId
 */
export const loadTestSelectionData = (sporters, testData) => {
  const testSet = loadTestSet();
  const testSelectionData = {
    tests: Object.keys(testSet.tests).map((t) => {
      return {
        nid: t,
        tid: testSet.tests[t].testItems[0].id,
        title: testSet.tests[t].title,
        duration: testSet.tests[t].duration,
        completed: 0,
      };
    }),
    test_count: Object.keys(testSet.tests).length,
    results: {},
  };

  // Overwrite sporters & count sporters
  testSelectionData.athletes = sporters;
  testSelectionData.athlete_count = sporters.length;

  // Loop over all sporters to add completed count
  sporters.forEach((sp) => {
    // Get all sporters testdata for session (that is finished)
    const personsTestData = testData.filter((t) => {
      return t.personId === sp.id;
    });

    // Sort on latests tests
    const sortedPersonsTestData = personsTestData.sort(
      (a, b) => b.date_updated - a.date_updated
    );

    // Create counter for sporter tests completed count
    let count = 0;

    // Loop over all tests
    testSelectionData.tests.forEach((test) => {
      // Get testData of test from current sporter (finished and latest)
      const testFinishedFound = sortedPersonsTestData.find((ptd) => {
        if (!ptd.data) return false;
        const data = ptd.data;
        return data[test.nid] !== undefined;
      });

      // if testData found add 1 completed for test & sporter completed
      // also add the latest result to
      if (testFinishedFound) {
        count++;
        test.completed++;
        testSelectionData.results[sp.id] = Object.assign(
          testSelectionData.results[sp.id] || {},
          testFinishedFound.data
        );
      }
    });

    // Set completed count for sporter
    sp.completed = count;
  });
  return testSelectionData;
};

/**
 * loads the selected test set contains all info about the test
 */
export function loadTestSet() {
  return TESTS_DATA;
}

export function setTests(config) {
  // remove derived tests
  const filteredTests = {};
  config.tests.forEach((test) => {
    test.testItems.forEach((item) => {
      if (item.input_type !== "derived") {
        const testId = config.version === 2 ? test.testItems[0].id : test.id;
        filteredTests[testId] = test;
      }
    });
  });

  config.tests = filteredTests;

  TESTS_DATA = config;
}

export function errorHandler(error, router, customHTTPHandler) {
  if (error.response.status === 401) {
    router.push("/login");
  }
}

/**
 * save the test result
 * @param {Object} result
 * "31598": { //user_id
            "703641": { //test_id
                "703642": { //test_item_id
                    "2": [ // lateralities
                        30
                    ]
                }
            },
 */
export async function saveTestResult(sessionId, result = {}) {
  try {
    let saveTestResult = await axios.put(SESSION_URL + sessionId, {
      results: result,
    });
    return saveTestResult.data;
  } catch (error) {
    throw error;
  }
}
