import { observable } from 'mobx';
import { persist } from 'mobx-persist';

export default class TestModel {
  @persist
  @observable
  uid;

  @persist
  @observable
  title;

  @persist
  @observable
  completed;

  @persist
  @observable
  total;

  @persist('list')
  @observable
  sporters = [];

  @persist
  @observable
  duration;

  @persist
  @observable
  testsetId;

  constructor(uid, title, completed, total, duration, testsetId) {
    this.id = uid;
    this.title = title;
    this.completed = completed;
    this.total = total;
    this.duration = duration;
    this.testsetId = testsetId;
  }

  toJS() {
    return {
      uid: this.id,
      title: this.title,
      completed: this.completed,
      total: this.total,
      sporters: this.sporters,
      duration: this.duration,
      testsetId: this.testsetId
    };
  }

  static fromJS(object, total, testsetId) {
    return new TestModel(
      object.nid,
      object.title,
      object.completed,
      total,
      object.duration,
      testsetId
    );
  }
}
