import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import H3 from '../H3/H3';
import CloseButton from '../CloseButton/CloseButton';
import P from '../P/P';

const HeaderWrapper = styled.header`
  width: 100%;
  align-self: flex-start;
  overflow: hidden;
  padding: 40px;
  position: relative;
  flex: 0 0 110px;
  height: 110px;
  display: flex;
  justify-content: center;

  ${props =>
    props.sporter &&
    css`
      overflow: initial;
    `};

  button {
    position: absolute;
    right: 40px;
    top: 40px;
  }

  p {
    margin-top: 7px;
  }

  &.white {
    background: ${props => props.theme.colors.white};
  }

  &.blue {
    background: ${props => props.theme.colors.nightblue};
  }
`;

const NameHeader = styled(H3)`
  margin-bottom: 20px;
`;

const HeaderTitleWrapper = styled.div`
  padding-right: 30px;
  width: 100%;
  text-align: center;
`;

class HeaderAttempts extends React.Component {
  render() {
    const { title, subtitle, sporter, color, onClick } = this.props;

    let closeIconColor = 'blue';
    if (color === 'blue') {
      closeIconColor = 'white';
    }

    return (
      <HeaderWrapper className={color} sporter={sporter}>
        <HeaderTitleWrapper>
          <NameHeader inverted upper>
            {sporter}
          </NameHeader>
          <H3 inverted upper>
            {title}
          </H3>
          <P inverted italic>
            {subtitle}
          </P>
        </HeaderTitleWrapper>
        <CloseButton color={closeIconColor} onClick={onClick} />
      </HeaderWrapper>
    );
  }
}

HeaderAttempts.propTypes = {
  title: PropTypes.string,
  sporter: PropTypes.string,
  subtitle: PropTypes.string,
  color: PropTypes.oneOf(['blue', 'white'])
};

export default HeaderAttempts;
