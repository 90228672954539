import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from '../Button/Button';
import Icon from '../Icon/Icon';

const ButtonWrapper = styled(Button)`
  svg {
    width: 20px;
    height: 20px;
  }
`;

class CloseButton extends React.Component {
  render() {
    const { color, onClick } = this.props;

    let closeIcon = 'close-blue';
    if (color === 'white') {
      closeIcon = 'close';
    } else if (color === 'lightblue') {
      closeIcon = 'close-lightblue';
    } else if (color === 'lightblue-small') {
      closeIcon = 'close-lightblue-small';
    }

    return (
      <ButtonWrapper onClick={onClick}>
        <Icon id={closeIcon} />
      </ButtonWrapper>
    );
  }
}

CloseButton.propTypes = {
  onClick: PropTypes.func,
  color: PropTypes.oneOf(['blue', 'white', 'lightblue', 'lightblue-small'])
};

export default CloseButton;
