import React, { Component } from "react";
import PropTypes from "prop-types";
import OverlayPage from "components/OverlayPage/OverlayPage";
import Content from "components/OverlayPage/Content";
import Footer from "components/OverlayPage/Footer";
import H1 from "components/H1/H1";
import ProgressTimer from "components/ProgressTimer/ProgressTimer";
import Button from "components/Button/Button";
import { Howl } from "howler";

class Timer extends Component {
  constructor(props) {
    super(props);
    this.interval = 0;
  }
  static contextTypes = {
    //count: PropTypes.number.isRequired
  };

  static propTypes = {
    count: PropTypes.number,
  };

  static defaultProps = {
    count: 0,
    longBeep: new Howl({ src: ["/assets/sound/long_beep.mp3"] }),
  };

  state = {
    count: 0,
    countDown: this.props.count,
    counting: true,
    percentage: 1,
  };

  componentDidMount() {
    this.runTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  runTimer() {
    this.timer = setInterval(() => {
      this.updateTimer();
    }, 1000);
    this.updateTimer();
  }

  updateTimer() {
    if (!this.state.counting) {
      this.props.onComplete();
      clearInterval(this.timer);
    } else if (this.state.count === this.props.count - 1) {
      this.props.longBeep.play();
      this.setState({
        counting: false,
      });
    } else {
      this.setState({
        count: this.state.count + 1,
        countDown: this.state.countDown - 1,
        counting: true,
      });
    }

    if (!this.interval) {
      this.setState({
        percentage: 100,
      });
    }

    this.interval++;
  }

  onClickHandler(e) {
    e.preventDefault();
    clearInterval(this.timer);
    this.props.skipCounter();
  }

  render() {
    const counting = this.state.counting;
    const header = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {
        color: counting ? "blue" : "white",
      })
    );

    return (
      <OverlayPage color={counting ? "blue" : "white"}>
        {header}
        <Content>
          {counting ? (
            <ProgressTimer
              percentage={this.state.percentage}
              count={this.state.countDown}
              duration={this.props.count - 1}
            />
          ) : (
            <H1 highlighted semismall>
              {this.props.msg}
            </H1>
          )}
        </Content>
        <Footer>
          {counting && (
            <Button autoFocus secondary onClick={(e) => this.onClickHandler(e)}>
              {this.props.skipBtnLabel}
            </Button>
          )}
        </Footer>
      </OverlayPage>
    );
  }
}

export default Timer;
