import styled, { css } from 'styled-components';
//import clearFix from './mixins/clearFix';

const H3 = styled.h3`
  display: block;
  font-weight: 900;
  font-family: ${props => props.theme.fonts.fontfaceRegular};
  line-height: 1em;
  margin: 0;
  font-size: ${props => props.theme.fonts.sizeH3};
  color: ${props => props.theme.colors.nightblue};

  ${props =>
    props.upper &&
    css`
      text-transform: uppercase;
    `}
  
  ${props =>
    props.textcenter &&
    css`
      text-align: center;
    `}
  
  ${props =>
    props.inverted &&
    css`
      color: ${props => props.theme.colors.white};
    `}
  
  ${props =>
    props.highlighted &&
    css`
      color: ${props => props.theme.colors.blue};
    `};

  ${props =>
    props.timer &&
    css`
      font-size: 30px;
      font-family: ${props => props.theme.fonts.fontfaceBold};
    `}
`;

export default H3;
