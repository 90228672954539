import { observable, action, runInAction, computed } from "mobx";
import InfoModel from "./model/InfoModel";
import { persist } from "mobx-persist";

export default class InfoStore {
  @persist("object")
  @observable
  infoModel;

  constructor(sessionStore, testStore) {
    this.sessionStore = sessionStore;
    this.testStore = testStore;
  }

  @action
  async loadTestSet(userTestId) {
    try {
      const userTest = this.testStore.getUserTestByUserTestId(userTestId);
      this.infoModel = null;
      if (userTest) {
        await this.sessionStore.loadTestSet(userTest.test.testsetId);
        const testSetDataForTest = this.sessionStore.getTestSetDataForTest(
          userTest.test.testsetId,
          userTest.test.id
        );
        if (!this.testStore.activeSporterTest) {
          this.testStore.setActiveTestById(userTestId);
        }
        this.testStore.activeSporterTest.data = testSetDataForTest;
        runInAction(() => {
          this.infoModel = new InfoModel(
            this.testStore.activeSporterTest.sporter,
            testSetDataForTest.test.title,
            testSetDataForTest.test.body,
            testSetDataForTest.test?.protocol ? testSetDataForTest.test.protocol.replace(/<p>&nbsp;<\/p>/g, "") : ""
          );
        });
      }
    } catch (error) {
      console.log("error", error.toString());
      runInAction(() => {});
    }
  }

  @computed
  get hasNoSessions() {
    return this.sessions.length === 0 && this.uiState.pendingRequestCount === 0;
  }

  getSessionTest() {
    if (this.sessions) {
      return { test: "test" };
    } else {
    }
  }
}
