import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Box } from "grid-styled";
import media from "styled-media-query";

import H2 from "../H2/H2";
import P from "../P/P";

const StatusInfoWrapper = styled.div`
  flex: 0 0 100%;
`;
const H2Wrapper = styled(H2)`
  font-size: 9vw;

  ${media.greaterThan("small")`
          font-size:5vw;     
      `};

  @media screen and (min-width: 600px) {
    font-size: 50px;
  }
`;

class StatusInfo extends React.Component {
  render() {
    const { title, text, mt, mb } = this.props;

    return (
      <StatusInfoWrapper>
        <H2Wrapper upper textcenter>
          {title}
        </H2Wrapper>
        <Box mt={mt || 15} mb={mb || 0}>
          <P textcenter dangerouslySetInnerHTML={{ __html: text }} />
        </Box>
      </StatusInfoWrapper>
    );
  }
}

StatusInfo.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};

export default StatusInfo;
