import React from "react";
import styled from "styled-components";
import { Box } from "grid-styled";
import media from "styled-media-query";

const ContentWrapper = styled(Box)`
  display: flex;
  overflow-y: auto;
  position: relative;
  align-items: center;
  justify-content: center;

  ${media.lessThan("small")`
    /* min-height: calc(100vh - 165px);
    position: absolute; */
    height: 100%;
  `};
`;

class Content extends React.Component {
  /* deleted  pl={20} pr={20} from ContentWrapper for inputsteps page */
  render() {
    return (
      <ContentWrapper width={1 / 1} flex="1 1 0%" is="div" style={{}}>
        {this.props.children}
      </ContentWrapper>
    );
  }
}

export default Content;
