import React from "react";
import { inject, observer } from "mobx-react";
import { injectIntl } from "react-intl";
import styled from "styled-components";
import OverlayPage from "components/OverlayPage/OverlayPage";
import Content from "components/OverlayPage/Content";
import InputSteps from "components/InputSteps/InputSteps";
import InputDuration from "components/InputDuration/InputDuration";
import FormData from "formdata-polyfill";
import media from "styled-media-query";
import messages from "../../../messages";
import trailsMessages from "../messages";

const TheForm = styled.form`
  min-height: 371px;

  ${media.lessThan("medium")`
    margin-top: 40px;
  `};
`;
@injectIntl
@inject("testStore")
@observer
class InputForm extends React.Component {
  handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    let submitData = {};
    for (let pair of formData.entries()) {
      submitData[pair[0]] = pair[1];
    }
    this.props.testStore.handleSubmit(submitData);
  }

  render() {
    const { testStore, intl } = this.props;
    let placeHolder;
    let value;

    const header = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {})
    );
    const testProgress = testStore.activeSporterTest.testProgress;
    console.log('testitems', testStore.activeTest.test.testItems);
    const formInputs = testStore.activeTest.test.testItems.map(
      (item, key) => {
        console.log('inputtype', item.input_type);
        switch (item.input_type) {
          // Input type is numeric input
          case 'free_number':
            placeHolder = intl.formatMessage(
              messages.appDefaultCopyAttemptsFormat,
              {
                current: testProgress.trialIndex + 1,
                total: testProgress.trials,
              }
            );
            value =
              testProgress.results.length > testProgress.trialIndex
                ? testProgress.results[testProgress.trialIndex][item.id]
                : undefined;
            return (
              <InputSteps
                key={key}
                name={item.id}
                placeholder={placeHolder}
                unit={item.unit}
                min={item.minimum}
                max={item.maximum}
                value={value}
                validInfo={intl.formatMessage(trailsMessages.trailsValidInfo, {
                  min: item.minimum,
                  max: item.maximum,
                })}
              />
            );
          //break;
          // Input type is radio list
          case 1:
            return false;
          // Input type is checkbox
          case 2:
            return false;
          // Input type is radio button group
          case 3:
            return false;
          // Input type is VAS Slider
          case 4:
            return false;
          // Input type duration field
          case 'chrono':
            value =
              testProgress.results.length > testProgress.trialIndex
                ? testProgress.results[testProgress.trialIndex][item.id]
                : undefined;
            return (
              <InputDuration
                key={key}
                name={item.id}
                min={item.minimum}
                max={item.maximum}
                value={value}
                settings={item.settings}
                unit={item.unit}
              />
            );
          // Input type is select list
          case 7:
            return false;
          // Input type is checkbox with input if selected
          case 12:
            return false;
          default:
            return false;
        }
      }
    );

    return (
      <OverlayPage color="blue">
        {" "}
        {header}{" "}
        <Content>
          <TheForm id="the-form" onSubmit={(e) => this.handleSubmit(e)}>
            {" "}
            {formInputs}{" "}
          </TheForm>{" "}
        </Content>{" "}
      </OverlayPage>
    );
  }
}

export default InputForm;
