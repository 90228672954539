const theme = {
  colors: {
    blue: '#365fea',
    blue30: 'rgba(54,95,234,0.3)',
    nightblue: '#071649', //04113c
    nightblue10: 'rgba(7,22,73,0.1)',
    nightblue30: 'rgba(7,22,73,0.3)',
    nightblue50: 'rgba(7,22,73,0.5)',
    nightblue85: 'rgba(7,22,73,0.85)',
    orange: '#ff9800',
    green: '#1ccb96',
    grey: '#e0e2e5',
    lightgrey: '#fafafa',
    darkgrey: '#f1f2f5',
    white: '#ffffff',
    white10: 'rgba(255,255,255,0.1)',
    white25: 'rgba(255,255,255,0.25)',
    white30: 'rgba(255,255,255,0.3)',
    white50: 'rgba(255,255,255,0.5)',
    danger: '#ff3333',
    danger10: 'rgba(255,51,51,0.1)',
    success: 'rgb(28,203,150)',
    succes10: 'rgba(28,203,150,0.1)',
    warning: 'rgb(255,152,0)',
    warning10: 'rgba(255,152,0,0.1)'
  },
  fonts: {
    fontface: '"Lato",sans-serif',
    fontfaceLight: '"latolight",sans-serif',
    fontfaceRegular: '"latoregular",sans-serif',
    fontfaceBold: '"latoblack",sans-serif',
    sizeH1: '160px',
    sizeH1SemiSmall: '100px',
    sizeH1Small: '80px',
    sizeH2: '50px',
    sizeH3: '20px',
    sizeBase: '14px',
    sizeSmall: '12px',
    sizeSuperSmall: '10px'
  },
  zIndex: {
    header: 2,
    drawer: 10,
    overlayPage: 20,
    alert: 30,
    loaderOverlay: 40
  }
};

//https://www.npmjs.com/package/styled-media-query
/*const media = generateMedia({
    small: '480px',
    medium: '768px',
    large: '1024px',
    huge: '1440px'
});*/

export default theme;
