import React from "react";
import { injectIntl } from "react-intl";
import ListLabelButton from "components/ListLabelButton/ListLabelButton";
import ListIconButton from "components/ListIconButton/ListIconButton";
import { getStatusColor } from "../utils";
import messages from "../../../messages";

const ListLabelSporterWrapperSelected = ({
  intl,
  data,
  onClick,
  selectedTestId,
  sporterTestList
}) => {
  const info = intl.formatMessage(messages.appDefaultCopyAgeFormat, {
    age: data.age
  });
  let statusLabel = `${data.completed}/${data.total}`;
  let statusColor = getStatusColor(data);
  const isCompleted = data.tests.toJS().includes(selectedTestId);

  const isBusy = sporterTestList.find(
    sporterTest =>
      sporterTest.sporter.id === data.id &&
      sporterTest.test.id === selectedTestId
  );

  if (isBusy) {
    statusLabel = `${isBusy.testProgress.trialIndex}/${
      isBusy.testProgress.trials
    }`;
  }

  return (
    <div>
      {isCompleted && !isBusy && (
        <ListIconButton
          gray
          label={data.firstname}
          extraLabel={data.lastname}
          statusColor="green"
          statusIcon="check"
          info={info}
          onClick={() => onClick(data.id, true)}
        />
      )}

      {isBusy && (
        <ListLabelButton
          gray
          label={data.firstname}
          extraLabel={data.lastname}
          statusColor={statusColor}
          statusLabel={statusLabel}
          info={info}
          onClick={() => onClick(data.id)}
        />
      )}

      {!isCompleted && !isBusy && (
        <ListIconButton
          gray
          label={data.firstname}
          extraLabel={data.lastname}
          statusColor="orange"
          statusIcon="clipboard"
          info={info}
          onClick={() => onClick(data.id)}
        />
      )}
    </div>
  );
};

export default injectIntl(ListLabelSporterWrapperSelected);
