import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from '../Button/Button';
import textStyle from '../P/textStyle';

const ButtonWrapper = styled(Button)`
  padding-bottom: 10px;
  position: relative;

  &.active {
    span {
      font-weight: bold;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background: ${props => props.theme.colors.blue};
    }
  }
`;
const ButtonText = styled.span`
  ${textStyle};
  font-size: ${props => props.theme.fonts.sizeSuperSmall};
`;

const TabBarButton = ({ label, active, onClick }) => {
  return (
    <ButtonWrapper className={active} onClick={onClick}>
      <ButtonText upper>{label}</ButtonText>
    </ButtonWrapper>
  );
};

TabBarButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  active: PropTypes.string
};

export default TabBarButton;
