import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import { Circle } from "rc-progress";
const CircleWrapperClass = styled.div`
  svg {
    width: 100%;

    .rc-progress-circle-trail {
      stroke: #79809b;
      stroke-width: 5;
    }
    .rc-progress-circle-path {
      stroke-linecap: square;
      transition: stroke-dasharray ${props => props.duration}s linear 0ms !important;
    }
  }
`;

class CircleWrapper extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.percent !== this.props.percent;
  }
  render() {
    const { percent, duration } = this.props;
    return (
      <CircleWrapperClass duration={duration}>
        <Circle
          percent={percent}
          strokeWidth="5"
          strokeLinecap="butt"
          strokeColor="#FFF"
        />
      </CircleWrapperClass>
    );
  }
}

CircleWrapper.propTypes = {
  percentage: PropTypes.number
};

export default CircleWrapper;
