var MILLISECONDS_IN_A_YEAR = 1000 * 60 * 60 * 24 * 365;
export function getAgeFromDOB(time) {
  var dateArray = time.split('-');
  var years =
    (new Date() - new Date(dateArray[0], dateArray[1], dateArray[2])) /
    MILLISECONDS_IN_A_YEAR;
  return Math.floor(years);
}

export function getAgeFromDateString(dateString) {
  const ageDifMs = Date.now() - new Date(dateString).getTime();
  const ageDate = new Date(ageDifMs);

  return Math.abs(ageDate.getUTCFullYear() - 1970);
}
