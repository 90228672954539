import styled, { css } from "styled-components";

const Button = styled.button`
  font-family: ${props => props.theme.fonts.fontfaceLight};
  font-weight: 200;
  display: inline-block;
  text-decoration: none;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  cursor: pointer;
  font-size: ${props => props.theme.fonts.sizeBase};

  ${props =>
    props.primary &&
    css`
      font-size: ${props => props.theme.fonts.sizeH3};
      letter-spacing: -0.5px;
      text-align: center;
      padding: 16px 24px 18px 24px;
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.blue};
    `}
  
  ${props =>
    props.secondary &&
    css`
      font-size: ${props => props.theme.fonts.sizeBase};
      text-align: center;
      padding: 10px 20px;
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.white10};
    `}
  
  ${props =>
    props.tertiary &&
    css`
      color: ${props => props.theme.colors.nightblue};
      background: ${props => props.theme.colors.white};
    `} 
    
  ${props =>
    props.rounded &&
    css`
      border-radius: 5px;
    `}
  
  ${props =>
    props.full &&
    css`
      display: block;
      width: 100%;
    `}

  ${props =>
    props.almostFull &&
    css`
      display: block;
      margin: auto;
      width: 100%;
      max-width: 92vw;
    `}
    
  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed !important;
      background: #999 !important;
      color: ${props => props.theme.colors.white}!important;
    `};
`;
export default Button;
