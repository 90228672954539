import React, { Component } from "react";
import { injectIntl } from "react-intl";
import AppRoute from "./AppRoute";

import BasePage from "./components/BasePage/BasePage";
import BaseHeader from "./components/BasePage/Header";
import BaseContent from "./components/BasePage/Content";
import LandscapeWarning from "./components/LandscapeWarning/LandscapeWarning";

import HeaderWrapper from "./components/Header/HeaderWrapper";

import messages from "./messages";

@injectIntl
export default class App extends Component {
  render() {
    const { intl } = this.props;
    return (
      <BasePage>
        <BaseHeader>
          <HeaderWrapper />
        </BaseHeader>
        <BaseContent>
          <AppRoute />
        </BaseContent>

        <LandscapeWarning
          title={intl.formatMessage(messages.warningPortraitViewTitle)}
          message={intl.formatMessage(messages.warningPortraitViewDescription)}
        />
      </BasePage>
    );
  }
}
