import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';

const ButtonWrapper = styled(Button)`
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grey};
  min-width: 39px;
`;
const StatusWrapper = styled.div`
  svg {
    height: 27px;
    width: 27px;
    transform: translateX(-2px);
    margin-top: 3px;
  }
`;

class FilterButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      direction: 'asc'
    };
    this.changeDirection = this.changeDirection.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      directions: nextProps.direction
    });
  }

  changeDirection() {
    const direction = this.state.direction === 'desc' ? 'asc' : 'desc';
    this.setState({ direction });
    if (this.props.onChangeDirection) {
      this.props.onChangeDirection(direction);
    }
  }

  render() {
    //const { onChangeDirection } = this.props;

    var directionIcon = 'sort-ascending';

    if (this.state.direction === 'desc') {
      directionIcon = 'sort-descending';
    }
    const directionIconId = directionIcon;

    return (
      <ButtonWrapper
        onClick={() => {
          this.changeDirection();
        }}
      >
        <StatusWrapper>
          <Icon id={directionIconId} />
        </StatusWrapper>
      </ButtonWrapper>
    );
  }
}

FilterButton.propTypes = {
  onChangeDirection: PropTypes.func,
  direction: PropTypes.oneOf(['asc', 'desc'])
};

export default FilterButton;
