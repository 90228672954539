import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Switch, Route } from "react-router-dom";

import { testRouteList } from "./RouteList";

@inject("testSelectionStore", "routing")
@observer
export default class TestApp extends Component {
  componentDidMount() {
    this.props.testSelectionStore.initializeApp();
  }
  render() {
    return (
      <Switch>
        {testRouteList.map(route => (
          <Route
            key={`${route.path}`}
            path={`${route.path}`}
            component={route.component}
          />
        ))}
      </Switch>
    );
  }
}
