export const getStatusColor = data => {
  let statusColor = 'blue';
  if (data.completed === data.total) {
    statusColor = 'green';
  } else if (data.completed < data.total && data.completed !== 0) {
    statusColor = 'orange';
  }

  return statusColor;
};
