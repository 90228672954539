import React from 'react';
import styled, { css } from 'styled-components';

const IconWrapper = styled.svg`${props => props.fill && css``};`;

class Icon extends React.Component {
  render() {
    const { id } = this.props;
    const svgPath = `/assets/images/sprites.svg#${id}`;
    return (
      <IconWrapper>
        <use xlinkHref={svgPath} />
      </IconWrapper>
    );
  }
}

export default Icon;
