import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router";
import { IntlProvider } from "react-intl";
import { Provider } from "mobx-react";
import { ThemeProvider } from "styled-components";
import { createBrowserHistory } from "history";
import { RouterStore, syncHistoryWithStore } from "mobx-react-router";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { translationMessages } from "./i18n";

import TestStore from "./containers/test/store/TestStore";
import TestSelectionStore from "./containers/testselection/store/TestSelectionStore";
import InfoStore from "./containers/info/store/InfoStore";
import SessionsStore from "./containers/sessions/store/SessionStore";
import UiState from "./store/UiState";

import { GA_KEY } from "./constants";
import theme from "./theme";
import "./theme/global-styles.js";
import App from "./App";
import { StorageType } from "./enums";

// Import i18n messages

const gtag = window.gtag;

if (gtag && GA_KEY) {
  gtag("config", GA_KEY);
}

// setup all stores
const uiState = new UiState();
const routingStore = new RouterStore();
const sessionsStore = new SessionsStore(routingStore, uiState);
const testStore = new TestStore(routingStore, sessionsStore, uiState);
const infoStore = new InfoStore(sessionsStore, testStore);
const testSelectionStore = new TestSelectionStore(
  routingStore,
  uiState,
  testStore
);

const stores = {
  routing: routingStore,
  testStore,
  sessionsStore,
  testSelectionStore,
  infoStore,
  uiState,
};

/**
 * responsible for persistance of stores every store we want te keep alive between refreshes
 * we have to wait for the persiststore finish before we can render our views
 */
const persistStores = async () => {
  // const hydrate = create();
  /* await hydrate("testSelectionStore", stores.testSelectionStore);
  await hydrate("sessionsStore", stores.sessionsStore);
  await hydrate("testStore", stores.testStore);
  await hydrate("infoStore", stores.infoStore);
  await hydrate("uiState", stores.uiState); */

  // wait to init the raction until all stores are hydrated
  // best option for now waiting for a respons of the mobx-restore libray
  stores.testStore.createReactions();
  stores.testSelectionStore.createReactions();

  stores.uiState.setLocale();
  init(stores.uiState.locale);
};

const init = (locale) => {

  if (process.env.REACT_APP_ENABLE_SENTRY === 'true') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      tunnel: process.env.REACT_APP_SENTRY_TUNNEL,
      integrations: [new BrowserTracing(), new Sentry.Replay()],
      environment: process.env.REACT_APP_ENV,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0
    });
  }

  const browserHistory = createBrowserHistory({ basename: `/${locale}` });
  const history = syncHistoryWithStore(browserHistory, routingStore);

  history.listen((location, action) => {
    sessionStorage.setItem(StorageType.ACTIVE_SESSION_PATH, location.pathname);
  });

  ReactDOM.render(
    <Provider {...stores}>
      <IntlProvider
        locale={locale.search("nl") !== -1 ? "nl" : "en"}
        messages={
          locale.search("nl") !== -1
            ? translationMessages.nl
            : translationMessages.en
        }
      >
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <App />
          </Router>
        </ThemeProvider>
      </IntlProvider>
    </Provider>,
    document.getElementById("root")
  );
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);

persistStores();

document.addEventListener("touchstart", () => {}, true);
