import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { injectIntl } from "react-intl";
import messages from "./messages";
import { Route, Switch } from "react-router";
import Counter from "./components/Counter";
import Timer from "./components/Timer";
import InputForm from "./components/InputForm";
import HeaderAttempts from "components/HeaderAttempts/HeaderAttempts";
import Status from "./components/Status";
import findSporterIdInList from "utils/findSporterIdInList";

@inject("routing", "testStore", "uiState")
@observer
class Test extends Component {
  componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      const isInList = findSporterIdInList(
        this.props.testStore.sporterTestList,
        nextProps.match.params.id
      );
      if (isInList.length === 0) {
        this.props.routing.push(
          `/test/selection/${this.props.uiState.sessionId}`
        );
      } else {
        this.props.testStore.setTrialIndex(nextProps.match.params.trailIndex);
      }
    }
  }

  componentWillMount() {
    if (!this.props.testStore.activeSporterTest) {
      this.props.routing.replace(
        `/test/selection/${this.props.uiState.sessionId}`
      );
    }
  }

  componentDidMount() {
    document.body.classList.remove("blue");
    this.props.testStore.getActiveTestItems();
  }
  render() {
    const { testStore } = this.props;
    let header;
    if (
      testStore.activeSporterTest &&
      testStore.activeSporterTest.testProgress
    ) {
      header = (
        <HeaderAttempts
          title={this.props.intl.formatMessage(messages.trialsHeader, {
            index: testStore.activeSporterTest.testProgress.trialIndex + 1,
            total: testStore.activeSporterTest.testProgress.trials
          })}
          subtitle={testStore.activeTest && testStore.activeTest.test.title}
          sporter={
            testStore.activeSporterTest.sporter.lastname +
            " " +
            testStore.activeSporterTest.sporter.firstname
          }
          color="blue"
          onClick={e => testStore.handleCloseClick(e)}
        />
      );
    }
    return (
      <div>
        {testStore.activeSporterTest && (
          <Switch>
            <Route
              exact
              path={`/test/test/:id/start/:trialIndex`}
              component={props => (
                <Status
                  title={
                    testStore.activeTest && testStore.activeTest.test.title
                  }
                  text={testStore.activeTest && testStore.activeTest.test.body}
                  label={this.props.intl.formatMessage(
                    testStore.activeSporterTest.testProgress.trials > 1
                      ? messages.trialsStart
                      : messages.trialStart,
                    {
                      index:
                        testStore.activeSporterTest.testProgress.trialIndex + 1
                    }
                  )}
                  onClick={e => testStore.handleStartTest(e)}
                />
              )}
            />
            <Route
              exact
              path={`/test/test/:id/counter/:trialIndex`}
              component={() => (
                <Counter
                  count={testStore.countDown}
                  msg={this.props.intl.formatMessage(messages.trialsGo)}
                  onComplete={() => testStore.handleCountComplete()}
                >
                  {header}
                </Counter>
              )}
            />
            <Route
              exact
              path={`/test/test/:id/timer/:trialIndex`}
              component={props => (
                <Timer
                  count={testStore.timer}
                  msg={this.props.intl.formatMessage(messages.trialsStop)}
                  skipBtnLabel={this.props.intl.formatMessage(
                    messages.trialsSkip
                  )}
                  onComplete={() => testStore.handleTimerComplete()}
                  skipCounter={() => testStore.handleSkipTimer()}
                >
                  {header}
                </Timer>
              )}
            />
            <Route
              exact
              path={`/test/test/:id/input/:trialIndex`}
              component={props => <InputForm>{header}</InputForm>}
            />
            <Route
              exact
              path={`/test/test/:id/rest/:trialIndex`}
              component={props => (
                <Status
                  title={this.props.intl.formatMessage(messages.trialsRest)}
                  text={this.props.intl.formatMessage(
                    messages.trialsStopDescription
                  )}
                  label={this.props.intl.formatMessage(messages.trialsStopBtn, {
                    index:
                      testStore.activeSporterTest.testProgress.trialIndex + 1
                  })}
                  onClick={e => testStore.handleNextClick(e)}
                />
              )}
            />
            <Route
              exact
              path={`/test/test/:id/done/:trialIndex`}
              component={props => (
                <Status
                  title={this.props.intl.formatMessage(messages.trialsDone)}
                  text={this.props.intl.formatMessage(
                    messages.trialsDoneDescription
                  )}
                  label={this.props.intl.formatMessage(messages.trialsDoneBtn)}
                  onClick={e => testStore.handleCompletedClick(e)}
                />
              )}
            />
          </Switch>
        )}
      </div>
    );
  }
}

export default injectIntl(Test);
