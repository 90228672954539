import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import styled from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";

import H3 from "components/H3/H3";
import P from "components/P/P";
import TabBarList from "components/TabBarList/TabBarList";
import TabBarButton from "components/TabBarButton/TabBarButton";
import SearchFilterList from "components/SearchFilterBox/SearchFilterList";
import ListLabelSporterWrapper from "./components/ListLabelSporterWrapper";
import ListLabelTestWrapper from "./components/ListLabelTestWrapper";
import ListLabelTestWrapperSelected from "./components/ListLabelTestWrapperSelected";
import ListLabelSporterWrapperSelected from "./components/ListLabelSporterWrapperSelected";
import SelectedHeader from "./components/SelectedHeader";

import { SPORTER_STATE, TEST_STATE } from "./constants";
import messages from "./messages";
import defaultMessages from "../../messages";

const ContentWrapper = styled.div`
  h3,
  .backButton {
    margin-top: 33px;
  }
  p {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .searchList {
    margin-top: 25px;
  }
`;

@injectIntl
@inject("testSelectionStore", "uiState", "testStore")
@observer
export default class TestSelection extends Component {
  componentDidMount() {
    document.body.classList.remove("blue");
    this.props.testSelectionStore.restoreState();
    this.props.testSelectionStore.injectIntl(this.props.intl);
    this.props.testStore.injectIntl(this.props.intl);
    this.props.testStore.clearActiveSporterTest();
  }

  render() {
    const { testSelectionStore, testStore, uiState, intl } = this.props;
    const {
      selectedSporter,
      selectedTest,
      selectedState,
      sporters,
      tests = []
    } = testSelectionStore;
    const hasSelection = selectedSporter || selectedTest;
    const labelNoResults = intl.formatMessage(messages.appNoResultsLabel);
    const labelLoadingResults = intl.formatMessage(messages.appResultsLoadingLabel);
    return (
      <ContentWrapper>
        <div>
          {hasSelection && selectedSporter && (
            <SelectedHeader
              onClick={() => testSelectionStore.resetState(SPORTER_STATE)}
              title={`${selectedSporter.lastname} ${selectedSporter.firstname}`}
              backMessage={intl.formatMessage(
                messages.appBackToAllSportersButton
              )}
              type={intl.formatMessage(messages.appTestTypeLabel)}
            />
          )}

          {hasSelection && selectedTest && (
            <SelectedHeader
              onClick={() => testSelectionStore.resetState(TEST_STATE)}
              title={selectedTest.title}
              backMessage={intl.formatMessage(messages.appBackToAllTestsButton)}
              type={intl.formatMessage(messages.appSporterTypeLabel)}
            />
          )}

          {!hasSelection && (
            <div>
              <H3>
                {uiState.sessionName ? (
                  uiState.sessionName
                ) : (
                  <FormattedMessage {...messages.appTitleLabel} />
                )}
              </H3>
              <P>
                <FormattedMessage {...messages.appDescriptionLabel} />
              </P>
              <TabBarList
                className="tabBarList"
                label="Preview button"
                activeIndex={selectedState}
                onChange={activeIndex =>
                  testSelectionStore.setSelectedState(activeIndex)
                }
              >
                <TabBarButton
                  label={intl.formatMessage(messages.appSportersTabLabel)}
                />
                <TabBarButton
                  label={intl.formatMessage(messages.appTestsTabLabel)}
                />
              </TabBarList>
            </div>
          )}
        </div>

        {selectedState === SPORTER_STATE && !selectedTest && (
          <SearchFilterList
            className="searchList"
            sortBy="firstname"
            list={sporters}
            labelNoResults={labelNoResults}
            labelLoadingResults={labelLoadingResults}
          >
            <ListLabelSporterWrapper
              onClick={value => testSelectionStore.selectSporterById(value)}
            />
          </SearchFilterList>
        )}

        {selectedState === SPORTER_STATE && selectedTest && (
          <SearchFilterList
            className="searchList"
            sortBy="firstname"
            list={sporters}
            labelNoResults={labelNoResults}
            labelLoadingResults={labelLoadingResults}
          >
            <ListLabelSporterWrapperSelected
              selectedTestId={selectedTest.id}
              sporterTestList={testStore.sporterTestList}
              onClick={(value, showWarning = false) => {
                if (showWarning === true) {
                  uiState.createAlert({
                    title: intl.formatMessage(
                      messages.modalTestAlreadyFilledInTitle
                    ),
                    message: intl.formatMessage(
                      messages.modalTestAlreadyFilledInDescription
                    ),
                    ok: intl.formatMessage(defaultMessages.appDefaultCopyOK),
                    okHandler: () => {
                      testSelectionStore.selectSporterById(value);
                    },
                    cancel: intl.formatMessage(
                      defaultMessages.appDefaultCopyCancel
                    )
                  });
                } else {
                  testSelectionStore.selectSporterById(value);
                }
              }}
            />
          </SearchFilterList>
        )}

        {selectedState === TEST_STATE && !selectedSporter && (
          <SearchFilterList
            className="searchList"
            sortBy="title"
            list={tests}
            labelNoResults={labelNoResults}
            labelLoadingResults={labelLoadingResults}
          >
            <ListLabelTestWrapper
              onClick={value => testSelectionStore.selectTestById(value)}
            />
          </SearchFilterList>
        )}
        {selectedState === TEST_STATE && selectedSporter && (
          <SearchFilterList
            className="searchList"
            sortBy="title"
            list={tests}
            labelNoResults={labelNoResults}
            labelLoadingResults={labelLoadingResults}
          >
            <ListLabelTestWrapperSelected
              selectedSporter={selectedSporter}
              sporterTestList={testStore.sporterTestList}
              onClick={(value, showWarning = false) => {
                if (showWarning === true) {
                  uiState.createAlert({
                    title: intl.formatMessage(
                      messages.modalTestAlreadyFilledInTitle
                    ),
                    message: intl.formatMessage(
                      messages.modalTestAlreadyFilledInDescription
                    ),
                    ok: intl.formatMessage(defaultMessages.appDefaultCopyOK),
                    okHandler: () => {
                      testSelectionStore.selectTestById(value);
                    },
                    cancel: intl.formatMessage(
                      defaultMessages.appDefaultCopyCancel
                    )
                  });
                } else {
                  testSelectionStore.selectTestById(value);
                }
              }}
            />
          </SearchFilterList>
        )}
      </ContentWrapper>
    );
  }
}
