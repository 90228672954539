import React from 'react';
import styled, { css } from 'styled-components';
import Button from '../Button/Button';

const ButtonWrapper = styled(Button)`
  font-size: ${props => props.theme.fonts.sizeBase};
  text-align: center;
  padding: 10px 20px;
  background: ${props => props.theme.colors.white10};
  color: ${props => props.theme.colors.blue};

  ${props =>
    props.danger &&
    css`
      background: ${props => props.theme.colors.danger10}!important;
      color: ${props => props.theme.colors.danger}!important;
    `};

  ${props =>
    props.success &&
    css`
      background: ${props => props.theme.colors.succes10}!important;
      color: ${props => props.theme.colors.success}!important;
    `};

  ${props =>
    props.warning &&
    css`
      background: ${props => props.theme.colors.warning10}!important;
      color: ${props => props.theme.colors.warning}!important;
    `};
`;

class ColoredButton extends React.Component {
  render() {
    return <ButtonWrapper {...this.props}>{this.props.children}</ButtonWrapper>;
  }
}

export default ColoredButton;
