import { observable, computed, action } from "mobx";
import { persist } from "mobx-persist";
import AlertModel from "store/model/AlertModel";
import { DEFAULT_LOCALE } from "../constants";
import { getLocalFromUrl, getLocalFromBrowser } from "../utils/locale";

class UiState {
  @observable
  locale = DEFAULT_LOCALE;

  @observable pendingRequestCount = 0;

  @persist
  @observable
  groupId;

  @persist
  @observable
  sessionId;

  @persist
  @observable
  organisationName = "";

  @observable isMenuOpen = false;

  @observable alertModel;

  @observable isHan = null;

  @computed
  get appIsInSync() {
    return this.pendingRequestCount === 0;
  }

  @action
  setAlertModel(alertModel) {
    this.alertModel = alertModel;
  }

  @action
  removeAlertModel() {
    this.alertModel = null;
  }

  setLocale() {
    this.locale = getLocalFromUrl() || getLocalFromBrowser();
  }

  createAlert({ title, message, ok, okHandler, cancel }) {
    const alertModel = new AlertModel(
      title,
      message,
      ok,
      () => {
        try {
          okHandler();
        } catch (error) {}
        this.removeAlertModel();
      },
      cancel,
      () => {
        this.removeAlertModel();
      }
    );
    this.setAlertModel(alertModel);
  }

  @action
  setSessionName(value) {
    this.sessionName = value;
  }

  @action
  setOrganisationName(value) {
    this.organisationName = value;
  }

  @action
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  @action
  hideMenu() {
    this.isMenuOpen = false;
  }

  @action
  setHan(value) {
    this.isHan = value;
  }
}

export default UiState;
