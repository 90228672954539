import React from 'react';
import styled from 'styled-components';

import H3 from '../H3/H3';

const InitialsBoxWrapper = styled.div`
  background: ${props => props.theme.colors.blue};
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;

  h3 {
    width: 100%;
    font-weight: 400;
  }
`;

class InitialsBox extends React.Component {
  render() {
    return (
      <InitialsBoxWrapper>
        <H3 inverted textcenter>
          {this.props.children}
        </H3>
      </InitialsBoxWrapper>
    );
  }
}

export default InitialsBox;
