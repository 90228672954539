import styled, { css } from 'styled-components';

const Input = styled.input`
  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.nightblue};
  font-size: ${props => props.theme.fonts.sizeBase};
  padding: 10px;
  outline: none;
  font-family: ${props => props.theme.fonts.fontfaceLight};
  line-height: 1em;
  width: 100%;
  -webkit-appearance: none;
  border-radius: 0;

  &[type='number'] {
    -moz-appearance: textfield;
  }
  &[type='number']:hover,
  &[type='number']:focus {
    -moz-appearance: number-input;
  }
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  &::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: ${props => props.theme.colors.nightblue30};
    line-height: normal !important;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: ${props => props.theme.colors.nightblue30};
    opacity: 1;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: ${props => props.theme.colors.nightblue30};
    opacity: 1;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${props => props.theme.colors.nightblue30};
  }
  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${props => props.theme.colors.nightblue30};
  }

  &::-ms-clear {
    display: none;
  }

  ${props =>
    props.large &&
    css`
      border: none !important;
      padding: 20px 75px 25px 10px;
      text-align: center;
      font-size: ${props => props.theme.fonts.sizeH1Small};
      color: ${props => props.theme.colors.nightblue};

      @media screen and (min-width: 370px) {
        padding: 20px 75px;
      }

      /*&::-webkit-input-placeholder {
        color: ${props => props.theme.colors.nightblue30};
        font-size: ${props => props.theme.fonts.sizeH3};
        transform: translateY(-20px);
        font-weight: 200;
        line-height:normal!important;
      }
      &:-moz-placeholder {
        color: ${props => props.theme.colors.nightblue30};
        font-size: ${props => props.theme.fonts.sizeH3};
        transform: translateY(-20px);
        opacity: 1;
        font-weight: 200;
      }
      &::-moz-placeholder {
        color: ${props => props.theme.colors.nightblue30};
        font-size: ${props => props.theme.fonts.sizeH3};
        transform: translateY(-20px);
        opacity: 1;
        font-weight: 200;
      }
      &:-ms-input-placeholder {
        color: ${props => props.theme.colors.nightblue30};
        font-size: ${props => props.theme.fonts.sizeH3};
        transform: translateY(-20px);
        font-weight: 200;
      }
      &::input-placeholder {
        color: ${props => props.theme.colors.nightblue30};
        font-size: ${props => props.theme.fonts.sizeH3};
        transform: translateY(-20px);
        font-weight: 200;
      }*/
    `} ${props =>
      props.error &&
      css`
        color: ${props => props.theme.colors.danger};
        border-bottom-color: ${props => props.theme.colors.danger};
      `} ${props =>
      props.search &&
      css`
        background-image: url('/assets/images/svgstore/search.svg');
        background-repeat: no-repeat;
        background-position: 5px 5px;
        padding-left: 40px;
        border: 1px solid ${props => props.theme.colors.grey};

        ${props =>
          props.error &&
          css`
            border-color: red;
          `};
      `};
`;
export default Input;
