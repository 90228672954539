/*
 * Inputduration Messages
 *
 * This contains all the text for the Inputduration component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  start: {
    id: "chrono.start.message",
    defaultMessage: "START"
  },
  timer: {
    id: "chrono.timer.message",
    defaultMessage: "TIMER"
  },
  stop: {
    id: "chrono.stop.message",
    defaultMessage: "STOP"
  },
  skip: {
    id: "chrono.skip.message",
    defaultMessage: "Skip"
  },
  endTimer: {
    id: "chrono.endTimer.message",
    defaultMessage: "Eind tijd"
  },
  saveTimer: {
    id: "chrono.save.timer.message",
    defaultMessage: "Opslaan"
  }
});
