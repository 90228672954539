import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Icon from "../Icon/Icon";
import Button from "../Button/Button";

const TestersBarWrapper = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.white10};
  padding-left: 20px;
  padding-right: 10px;
  display: flex;
  overflow: hidden;
  height: 50px;
`;

const AddButton = styled(Button)`
  font-size: ${props => props.theme.fonts.sizeSuperSmall};
  text-align: center;
  padding: 4px 10px;
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.blue};
  font-family: ${props => props.theme.fonts.fontfaceRegular};
  /*width: 30px;*/
  height: 30px;
  position: relative;
  border-radius: 2px;
  margin-right: 10px;
  display: block;

  span {
    padding-left: 15px;
  }

  svg {
    width: 30px;
    height: 30px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    margin-top: 1px;
  }
`;

const Triangle = styled.div`
  width: 10px;
  height: 10px;
  background: ${props => props.theme.colors.white};
  position: absolute;
  bottom: -17px;
  left: calc(50% - 10px);
  transform: rotate(45deg);
`;

const AddButtonWrapper = styled.div`
  flex: 0 0 30px;
  position: relative;
  margin: 10px 0;
`;

const TestersBarWrapperList = styled.div`
  flex: 1 1 auto;
  height: 80px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  margin: 10px 0;

  .testersbutton {
    white-space: nowrap;
  }
`;

class TestersBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollLeft: 0
    };
  }
  scrollHorizontally(e) {
    let delta = Math.max(-1, Math.min(1, e.deltaY));
    this.setState({ scrollLeft: delta * 40 });
    e.preventDefault();
  }

  componentDidUpdate() {
    const elem = document.getElementById("testerList");
    if (elem) {
      elem.scrollLeft += this.state.scrollLeft;
    }
  }
  render() {
    const { showArrowUnderAdd, addHandler, label } = this.props;

    return (
      <TestersBarWrapper>
        <AddButtonWrapper onClick={addHandler}>
          <AddButton onClick={addHandler}>
            <Icon id="plus" /> <span>{label}</span>
          </AddButton>
          {showArrowUnderAdd && <Triangle />}
        </AddButtonWrapper>
        {this.props.children && (
          <TestersBarWrapperList
            id="testerList"
            onWheel={e => this.scrollHorizontally(e)}
            {...this.props}
          >
            {this.props.children}
          </TestersBarWrapperList>
        )}
      </TestersBarWrapper>
    );
  }
}

TestersBar.propTypes = {
  showArrowUnderAdd: PropTypes.bool,
  addHandler: PropTypes.func
};

export default TestersBar;
