import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from '../Button/Button';
import CloseButton from '../CloseButton/CloseButton';

const ButtonWrapper = styled(Button)`
  font-size: ${props => props.theme.fonts.sizeSuperSmall};
  text-align: center;
  padding: 9px 10px;
  margin-right: 10px;
  height: 30px;
  background: ${props => props.theme.colors.white30};
  color: ${props => props.theme.colors.white};
  font-family: ${props => props.theme.fonts.fontfaceRegular};
  border-radius: 2px;
  display: flex;
  align-items: center;
`;

const DivWrapper = styled.div`
  font-size: ${props => props.theme.fonts.sizeSuperSmall};
  text-align: center;
  padding: 0px 27px 0px 10px;
  margin-right: 10px;
  height: 30px;
  background: ${props => props.theme.colors.white}!important;
  color: ${props => props.theme.colors.blue};
  font-family: ${props => props.theme.fonts.fontfaceRegular};
  border-radius: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  button {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 10px;
    height: 30px;
    align-items: center;
  }

  svg {
    width: 8px;
    height: 8px;
    margin-top: 2px;
  }
`;

const ButtonLabel = styled.span`
  display: flex;
`;

const InfoLabel = styled.span`
  display: flex;
  color: ${props => props.theme.colors.white50};
  font-family: ${props => props.theme.fonts.fontfaceLight};
  margin-left: 10px;
  margin-top: 1px;
`;

class TestersButton extends React.Component {
  render() {
    const { info, label, active, onClick, deleteHandler } = this.props;

    if (active) {
      return (
        <DivWrapper className="testersbutton active">
          <ButtonLabel onClick={onClick}>{label}</ButtonLabel>
          <CloseButton color="lightblue" onClick={deleteHandler} />
        </DivWrapper>
      );
    } else {
      return (
        <ButtonWrapper className="testersbutton" onClick={onClick}>
          <ButtonLabel>{label}</ButtonLabel>
          <InfoLabel>{info}</InfoLabel>
        </ButtonWrapper>
      );
    }
  }
}

TestersButton.propTypes = {
  onClick: PropTypes.func,
  deleleHandler: PropTypes.func,
  info: PropTypes.string,
  label: PropTypes.string.isRequired,
  active: PropTypes.bool
};

export default TestersButton;
