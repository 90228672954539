/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  trialsHeader: {
    id: "test.trialsHeader.message",
    defaultMessage: "Poging {index}/{total}"
  },
  trialStart: {
    id: "test.trialStart.message",
    defaultMessage: "Start"
  },
  trialsStart: {
    id: "test.trialsStart.message",
    defaultMessage: "Start poging {index}"
  },
  trialsGo: {
    id: "test.trialsGo.message",
    defaultMessage: "GO"
  },
  trialsStop: {
    id: "test.trialsStop.message",
    defaultMessage: "STOP"
  },
  trialsSkip: {
    id: "test.trialsSkip.message",
    defaultMessage: "Overslaan"
  },
  trialsRest: {
    id: "test.trialsRest.message",
    defaultMessage: "Rust"
  },
  trialsStopDescription: {
    id: "test.trialsStopDescription.message",
    defaultMessage:
      "Begin aan de volgende pogingen wanneer de sporter terug op adem en krachten is."
  },
  trialsStopBtn: {
    id: "test.trialsStopBtn.message",
    defaultMessage: "Start poging {index}"
  },
  trialsDone: {
    id: "test.trialsDone.message",
    defaultMessage: "Klaar"
  },
  trialsDoneDescription: {
    id: "test.trialsDoneDescription.message",
    defaultMessage:
      "Alle pogingen zijn uitgevoerd. Klik ‘Opslaan en afsluiten’ om de resultaten te bewaren."
  },
  trialsDoneBtn: {
    id: "test.trialsDoneBtn.message",
    defaultMessage: "Opslaan & Afsluiten"
  },
  trailsValidInfo: {
    id: "test.valid.info.message",
    defaultMessage: "Waarde tussen min: {min} en max: {max}"
  },
  modalCancelTrailTitle: {
    id: "modal.cancel.trail.title",
    defaultMessage: "Poging afsluiten?"
  },
  modalCancelTrailDescription: {
    id: "modal.cancel.trail.description",
    defaultMessage:
      "Ben je zeker dat je de huidige poging wil afsluiten? Resultaten worden niet opgeslagen en de poging dient te worden overgedaan."
  }
});
