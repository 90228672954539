import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import textStyle from '../P/textStyle';

const ButtonWrapper = styled(Button)`
  text-align: left;
  display: flex;
  background: none;
`;
const StatusWrapper = styled.div`
  svg {
    height: 27px;
    width: 27px;
  }
`;
const ButtonText = styled.span`
  ${textStyle};
  padding-top: 5px;
  padding-left: 10px;
  font-size: ${props => props.theme.fonts.sizeSuperSmall};
  color: ${props => props.theme.colors.nightblue50};
`;

class FilterButton extends React.Component {
  render() {
    const { icon, label, onClick } = this.props;

    return (
      <ButtonWrapper full onClick={onClick}>
        <StatusWrapper>
          <Icon id={icon} />
        </StatusWrapper>
        <ButtonText bold upper small>
          {label}
        </ButtonText>
      </ButtonWrapper>
    );
  }
}

FilterButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  label: PropTypes.string
};

export default FilterButton;
