import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Route, withRouter } from "react-router";

import TestApp from "./TestApp";
import Alert from "components/Alert/Alert";
import LoaderOverlay from "components/LoaderOverlay/LoaderOverlay";

@withRouter
@inject("routing", "uiState")
@observer
export default class AppRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { uiState } = this.props;
    const { appIsInSync, alertModel } = uiState;
    return (
      <React.Fragment>
        {!appIsInSync && <LoaderOverlay white />}
        {alertModel && <Alert {...alertModel} />}
        <Route path="/test" component={TestApp} />
        <Route path="/"></Route>
      </React.Fragment>
    );
  }
}
