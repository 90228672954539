import React from "react";
import styled from "styled-components";
import { Flex } from "grid-styled";

const PageWrapper = styled(Flex)`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

class BasePage extends React.Component {
  render() {
    return (
      <PageWrapper flexDirection="column" {...this.props}>
        {this.props.children}
      </PageWrapper>
    );
  }
}

BasePage.propTypes = {};

export default BasePage;
