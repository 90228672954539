import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import _ from "underscore";

import Input from "../Input/Input";
import Button from "../Button/Button";
import P from "../P/P";
import Icon from "../Icon/Icon";

const FormItemWrapper = styled.div`
  background: ${props => props.theme.colors.white};
  text-align: center;
  padding-bottom: 15px;
  position: relative;

  button {
    position: absolute;
    right: 20px;
    top: 29px;
    width: 40px;
    height: 80px;
    z-index: 100;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  input {
    position: relative;
    z-index: 1;
    background: none;
  }
`;

const SpanPlaceholder = styled.span`
  font-family: ${props => props.theme.fonts.fontfaceLight};
  color: ${props => props.theme.colors.nightblue30};
  font-size: ${props => props.theme.fonts.sizeH3};
  transform: translateY(-20px);
  font-weight: 200;
  position: absolute;
  left: 0;
  top: 75px;
  width: 100%;
`;

const ValidInfo = styled(P)`
  color: ${props => props.theme.colors.danger};
  ${props =>
    props.confirm &&
    css`
      color: ${props => props.theme.colors.nightblue};
    `};
`;

class InputSteps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value ? props.value : "",
      valid: false,
      submittable: false
    };
  }

  componentDidMount() {
    this.numInput.focus();
  }

  onChangeHandler(e) {
    e.preventDefault();
    let value = e.target.value;

    this.validateInput(value);
  }
  onClickHandler(e) {
    const submittable = this.validateInput(this.state.value);

    if (!submittable) {
      e.preventDefault();
    }
  }

  validateInput(value) {
    let valid;
    let submittable;
    if (_.isEmpty(value)) {
      this.setState({
        value: "",
        valid: false
      });
      return;
    }

    value = value.replace(",", ".");
    value = value.replace(/[^0-9,.]/g, "");

    valid = value && value >= this.props.min && value <= this.props.max;

    submittable = value && value >= 0;

    this.setState({
      value: value,
      valid: valid,
      submittable: submittable
    });

    return submittable;
  }

  render() {
    const { placeholder, unit, min, max, name, validInfo } = this.props;

    let validIcon =
      this.state.value !== "" && !this.state.valid ? "confirm-dark" : "confirm";
    let invalid = this.state.value !== "" && !this.state.valid;

    const constvalIconId = validIcon;

    return (
      <FormItemWrapper>
        <Input
          name={name}
          large
          value={this.state.value}
          type="text"
          pattern="\d+(\.\d*)?"
          min={min}
          max={max}
          step="any"
          onChange={e => this.onChangeHandler(e)}
          innerRef={input => {
            this.numInput = input;
          }}
          autoFocus
          autoComplete="off"
          error={invalid}
        />

        {this.state.value.length === 0 && (
          <SpanPlaceholder>{placeholder}</SpanPlaceholder>
        )}

        <P bold upper>
          {unit}
        </P>

        {validIcon === "confirm-dark" && <ValidInfo>{validInfo}</ValidInfo>}
        {validIcon === "confirm" && <ValidInfo confirm>{validInfo}</ValidInfo>}

        <Button valid={this.state.valid} onClick={e => this.onClickHandler(e)}>
          <Icon id={constvalIconId} />
        </Button>
      </FormItemWrapper>
    );
  }
}

InputSteps.propTypes = {
  placeholder: PropTypes.string,
  valid: PropTypes.bool
};

export default InputSteps;
