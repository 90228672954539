import { observable, action, reaction, computed, runInAction } from "mobx";
import { persist } from "mobx-persist";
import SporterTestModel from "./model/SporterTestModel";

import findSporterIdInList from "utils/findSporterIdInList";
import messages from "../messages";
import defaultMessages from "../../../messages";
import { MessageType, StorageType } from "../../../enums";

export default class TestStore {
  @persist("list", SporterTestModel)
  @observable
  sporterTestList = [];

  @persist
  @observable
  activeSporterTestId;

  @persist("object")
  @observable
  activeTest;

  @persist("object")
  @observable
  activeItem;

  countDown = 4;
  hasCounter;
  counterIsDone;
  timer;
  hasTimer;
  timerIsDone;

  constructor(routerStore, sessionsStore, uiState) {
    this.routerStore = routerStore;
    this.sessionStore = sessionsStore;
    this.uiState = uiState;
    this.activeSporterTestId = "14413-921164";
  }

  injectIntl = (intl) => {
    this.intl = intl;
  };

  createReactions() {
    reaction(
      () => this.sporterTestList.length,
      (length) => {
        if (length > 0) {
          // TODO redirect ook bij refresh tijdens test
          this.activeSporterTestId = this.sporterTestList[0].id;
          this.activeSportestTest = this.sporterTestList[0];
          this.redirectForActiveSporters(this.activeSportestTest);
        } else {
          this.routerStore.push(`/test/selection/${this.uiState.sessionId}`);
        }
      }
    );
  }

  /**
   * will try to add a new sporter/test combination to the sporterTestList list
   * if the combination can be added to the list the function will return true
   * else when the combination is already in the list the function will return false
   * @param {*} sporter
   * @param {*} test
   * @return boolean
   */
  @action
  addSporterTest(sporter, test) {
    const id = `${sporter.id}-${test.id}`;

    const isInList = findSporterIdInList(this.sporterTestList, id);
    if (isInList.length === 0) {
      const newSporter = observable(Object.assign({}, sporter));
      const newTest = observable(Object.assign({}, test));

      const sporterTest = this.createSporterTest(id, newSporter, newTest);

      this.activeSporterTestId = sporterTest.id;

      this.sporterTestList = [sporterTest, ...this.sporterTestList];
      return sporterTest;
    }

    return null;
  }

  @action
  createSporterTest(id, sporter, test) {
    const sporterTest = new SporterTestModel(id, sporter, test);
    const activeTest = this.getTestDataForTest(sporterTest);
    const activeItem = this.getActiveItem(activeTest);
    sporterTest.createTestProgress(activeItem.trials);

    return sporterTest;
  }

  @computed
  get activeSporterTest() {
    const result = findSporterIdInList(
      this.sporterTestList,
      this.activeSporterTestId
    );
    return result[0];
  }

  @action
  setActiveSporterTestId(id) {
    this.activeSporterTestId = id;
  }

  @action
  deleteById(id) {
    this.sporterTestList = this.sporterTestList.filter(
      (sporterTest) => sporterTest.id !== id
    );
  }

  @action
  clearActiveSporterTest() {
    this.activeSporterTestId = null;
  }

  @action
  setActiveTestById(id) {
    const isInList = findSporterIdInList(this.sporterTestList, id);
    if (isInList.length > 0) {
      const sporterTest = isInList[0];
      this.activeSporterTestId = sporterTest.id;
      this.redirectForActiveSporters(sporterTest);
    }
  }

  redirectForActiveSporters(sporterTest) {
    if (
      sporterTest.testProgress.trialIndex === sporterTest.testProgress.trials
    ) {
      this.routerStore.push(
        `/test/test/${this.activeSporterTestId}/done/${this.activeSporterTest.testProgress.trialIndex}`
      );
    } else if (
      sporterTest.testProgress &&
      sporterTest.testProgress.trialIndex > 0
    ) {
      this.routerStore.push(
        `/test/test/${this.activeSporterTestId}/start/${this.activeSporterTest.testProgress.trialIndex}`
      );
    } else {
      this.routerStore.push(`/test/info/${this.activeSporterTestId}`);
    }
  }

  getTestDataForTest(sporterTest) {
    const activeTest = this.sessionStore.getTestSetDataForTest(
      sporterTest.test.testsetId,
      sporterTest.test.id
    );

    return activeTest;
  }

  getActiveItem(activeTest) {
    return activeTest.test.testItems[0];
  }

  @action
  getActiveTestItems() {
    if (this.activeSporterTest) {
      this.activeTest = this.getTestDataForTest(this.activeSporterTest);
      this.activeItem = this.getActiveItem(this.activeTest);
      this.hasCounter =
        this.activeTest.test.countdown === true;
      this.timer = Number(this.activeTest.test.timer);
      this.hasTimer = Boolean(this.timer);
    }
  }

  @action
  resetTrial() {
    this.counterIsDone = false;
    this.timerIsDone = false;
  }

  startNextTrial() {
    this.resetTrial();
    this.navigateToNextView();
  }

  navigateToNextView() {
    if (this.hasCounter && !this.counterIsDone) {
      this.routerStore.push(
        `/test/test/${this.activeSporterTest.id}/counter/${this.activeSporterTest.testProgress.trialIndex}`
      );
    } else if (this.hasTimer && !this.timerIsDone) {
      this.routerStore.replace(
        `/test/test/${this.activeSporterTest.id}/timer/${this.activeSporterTest.testProgress.trialIndex}`
      );
    } else if (this.hasCounter || this.hasTimer) {
      this.routerStore.replace(
        `/test/test/${this.activeSporterTest.id}/input/${this.activeSporterTest.testProgress.trialIndex}`
      );
    } else {
      this.routerStore.push(
        `/test/test/${this.activeSporterTest.id}/input/${this.activeSporterTest.testProgress.trialIndex}`
      );
    }
  }

  getUserTestByUserTestId(id) {
    const isInList = findSporterIdInList(this.sporterTestList, id);
    if (isInList.length !== 0) {
      return isInList[0];
    }
    return null;
  }

  @action
  handleCloseClick(e) {
    e.preventDefault();
    this.uiState.createAlert({
      title: this.intl.formatMessage(messages.modalCancelTrailTitle),
      message: this.intl.formatMessage(messages.modalCancelTrailDescription),
      ok: this.intl.formatMessage(defaultMessages.appDefaultCopyClose),
      okHandler: () => {
        this.resetTrial();
        this.routerStore.push(`/test/selection/${this.uiState.sessionId}`);
      },
      cancel: this.intl.formatMessage(defaultMessages.appDefaultCopyCancel),
    });
  }
  @action
  handleStartTest() {
    this.resetTrial();
    this.navigateToNextView();
  }
  @action
  handleCountComplete() {
    this.counterIsDone = true;
    this.navigateToNextView();
  }
  @action
  handleTimerComplete() {
    this.timerIsDone = true;
    this.navigateToNextView();
  }
  @action
  handleSkipTimer() {
    this.timerIsDone = true;
    this.navigateToNextView();
  }
  @action
  handleNextClick() {
    this.startNextTrial();
  }
  @action
  async handleCompletedClick() {
    this.uiState.pendingRequestCount += 1;
    await this.submitResults();
    try {
      runInAction(() => {
        this.uiState.pendingRequestCount -= 1;
        this.sporterTestList.remove(this.activeSporterTest);
      });
    } catch (error) {
      this.uiState.pendingRequestCount -= 1;
    }
  }

  @action
  async submitResults() {
    // await saveTestResult(this.uiState.sessionId, this.activeSporterTest.getSaveData());
    const data = this.activeSporterTest.getSaveData();
    const message = {
      type: MessageType.TEST_FINISHED,
      data,
    };
    window.parent.postMessage(JSON.stringify(message), "*");

    sessionStorage.removeItem(StorageType.ACTIVE_SESSION_ID);
  }

  startTest = (sporter, redirect) => {
    const message = {
      type: MessageType.TEST_STARTED,
      data: { sporter },
    };
    window.parent.postMessage(JSON.stringify(message), "*");
    // this.routerStore.push(redirect);
    this.getActiveTestItems();
    this.handleStartTest();
  };

  @action
  handleSubmit(data) {
    this.activeSporterTest.setProgress(data);
    if (
      this.activeSporterTest.testProgress.trialIndex ===
      this.activeSporterTest.testProgress.trials
    ) {
      this.resetTrial();
      this.routerStore.push(
        `/test/test/${this.activeSporterTest.id}/done/${this.activeSporterTest.testProgress.trialIndex}`
      );
    } else if (!this.hasCounter) {
      this.startNextTrial();
    } else {
      this.routerStore.push(
        `/test/test/${this.activeSporterTest.id}/rest/${this.activeSporterTest.testProgress.trialIndex}`
      );
    }
  }

  @action
  setTrialIndex(index) {
    this.activeSporterTest.testProgress.trialIndex = Number(index);
  }
}
