/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  infoStartTestButton: {
    id: "info.starttest.button",
    defaultMessage: "Start test"
  },
  infoProtocolTitle: {
    id: "info.protocol.title",
    defaultMessage: "Protocol"
  }
});
