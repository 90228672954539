import styled, { css } from 'styled-components';

const H1 = styled.h1`
  display: block;
  font-weight: 600;
  font-family: ${props => props.theme.fonts.fontfaceBold};
  letter-spacing: 1.3px;
  font-size: ${props => props.theme.fonts.sizeH1};
  line-height: 1em !important;
  margin: 0;
  color: ${props => props.theme.colors.white};

  ${props =>
    props.semismall &&
    css`
      font-size: ${props => props.theme.fonts.sizeH1SemiSmall};
    `}
  
  ${props =>
    props.small &&
    css`
      font-size: ${props => props.theme.fonts.sizeH1Small};
    `} 
    
  ${props =>
    props.textcenter &&
    css`
      text-align: center;
    `}
  
  ${props =>
    props.inverted &&
    css`
      color: ${props => props.theme.colors.nightblue};
    `}
  
  ${props =>
    props.highlighted &&
    css`
      color: ${props => props.theme.colors.blue};
    `};
`;

export default H1;
