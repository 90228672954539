import { observable, action } from 'mobx';
import { persist } from 'mobx-persist';
import flatten from 'utils/flattenArray';
export default class TestProgressModel {
  @persist
  @observable
  trials;

  @persist
  @observable
  trialIndex;

  @persist('list')
  @observable
  results;

  constructor(trials) {
    this.trials = trials;
    this.trialIndex = 0;
    this.results = [];
  }

  @action
  setResult(result) {
    this.results[this.trialIndex] = result;
    this.trialIndex++;
  }

  getResult() {
    let resultSet = flatten(
      this.results.map(result => parseFloat(Object.values(result)))
    );

    const keyValue = Object.keys(this.results[0])[0];

    return {
      key: keyValue,
      resultSet
    };
  }

  toJS() {
    return {
      trials: this.trials,
      trialIndex: this.trialIndex,
      results: this.results.toJS()
    };
  }
}
