import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Box } from "grid-styled";

import InitialsBox from "../InitialsBox/InitialsBox";
import Icon from "../Icon/Icon";
import P from "../P/P";
import H3 from "../H3/H3";

const TestUserInfoWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TestUserInfoPersonWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-top: 3px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

class TestUserInfo extends React.Component {
  render() {
    const { initials, sex, name, age } = this.props;

    let sexIcon = sex;
    const sexIconId = sexIcon.toLowerCase();

    return (
      <TestUserInfoWrapper>
        <Box mb={25}>
          <InitialsBox>{initials}</InitialsBox>
        </Box>
        <H3>{name}</H3>
        <TestUserInfoPersonWrapper>
          <Icon id={sexIconId} />
          <P>
            |&nbsp;&nbsp;
            {age}
          </P>
        </TestUserInfoPersonWrapper>
      </TestUserInfoWrapper>
    );
  }
}

TestUserInfo.propTypes = {
  sex: PropTypes.oneOf(["male", "female"]),
  initials: PropTypes.string,
  name: PropTypes.string,
  age: PropTypes.string
};

export default TestUserInfo;
