import { observable, computed } from "mobx";
import { persist } from "mobx-persist";
import { getAgeFromDateString } from "../../../../utils/date";

export default class SporterModel {
  @persist
  @observable
  uid;

  @persist
  @observable
  lastname;

  @persist
  @observable
  firstname;

  @persist
  @observable
  birthdate;

  @persist
  @observable
  gender;

  @persist
  @observable
  completed;

  @persist("list")
  @observable
  tests = [];

  @persist
  @observable
  total;

  constructor(uid, lastname, firstname, birthdate, gender, completed, total) {
    this.id = uid;
    this.lastname = lastname;
    this.firstname = firstname;
    this.birthdate = birthdate;
    this.age = getAgeFromDateString(this.birthdate);
    this.gender = gender;
    this.completed = completed;
    this.total = total;
    this.tests = [];
  }

  @computed
  get fullname() {
    return `${this.firstname} ${this.lastname}`;
  }

  toJS() {
    return {
      uid: this.id,
      lastname: this.lastname,
      firstname: this.firstname,
      birthdate: this.birthdate,
      gender: this.gender,
      completed: this.completed,
      tests: this.tests,
      total: this.total,
    };
  }

  static fromJS(object, total) {
    return new SporterModel(
      object.id,
      object.lastname,
      object.firstname,
      object.birthdate,
      object.gender,
      object.completed,
      total
    );
  }
}
