import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Box } from 'grid-styled';

import H3 from '../H3/H3';
import P from '../P/P';
import ColoredButton from '../ColoredButton/ColoredButton';

const AlertWrapper = styled.div`
  background: ${props => props.theme.colors.nightblue50};
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => props.theme.zIndex.alert};
  display: flex;
  align-items: center;
`;
const AlertBox = styled.div`
  background: ${props => props.theme.colors.white};
  padding: 20px;
  width: 75%;
  max-width: 300px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
`;
const AlertBoxHeader = styled(Box)`
  display: flex;
  align-items: flex-start;

  h3 {
    flex: 1 1 auto;
    margin-right: 10px;
  }
`;
const AlertBoxButtons = styled(Box)`text-align: right;`;

class Alert extends React.Component {
  render() {
    const { title, message, ok, okHandler, cancel, cancelHandler } = this.props;
    return (
      <AlertWrapper>
        <AlertBox>
          <AlertBoxHeader mb={20}>
            <H3>{title}</H3>
            {/*<CloseButton onClick={cancelHandler} />*/}
          </AlertBoxHeader>
          <Box>
            <P>{message}</P>
          </Box>
          <AlertBoxButtons mt={30}>
            {cancel &&
            cancelHandler && (
              <ColoredButton onClick={cancelHandler}>{cancel}</ColoredButton>
            )}
            {ok &&
            okHandler && (
              <ColoredButton danger onClick={okHandler}>
                {ok}
              </ColoredButton>
            )}
          </AlertBoxButtons>
        </AlertBox>
      </AlertWrapper>
    );
  }
}

Alert.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  ok: PropTypes.string,
  okHandler: PropTypes.func,
  cancel: PropTypes.string,
  cancelHandler: PropTypes.func
};

export default Alert;
