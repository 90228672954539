import React from 'react';
import styled, { keyframes } from 'styled-components';
import Icon from '../Icon/Icon';

const rotate360 = keyframes`
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
`;

const LoaderWrapper = styled.div`
  svg {
    width: 40px;
    height: 40px;
    animation: ${rotate360} 1500ms linear infinite;
  }
`;

class Loader extends React.Component {
  render() {
    return (
      <LoaderWrapper>
        <Icon id="loading-icon" />
      </LoaderWrapper>
    );
  }
}

Loader.propTypes = {};

export default Loader;
