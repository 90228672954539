import React, { Component } from "react";
import { Box } from "grid-styled";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "underscore";
import SearchFilterBox from "./SearchFilterBox";
import P from "components/P/P";
const SearchListWrapper = styled.div`
  padding-top: 25px;
`;

export default class SearchFilterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: "",
      sortBy: "asc"
    };
  }

  renderChild(props, data) {
    let childProps = {};
    childProps["data"] = data;
    return React.Children.map(props.children, child => {
      return React.cloneElement(child, childProps);
    });
  }

  render() {

    let list = this.props.list.toJS();
    if (this.state.filter.length > 0) {
      list = list.filter(item => {
        let filterValue = Object.values(item)
          .toString()
          .toLowerCase()
          .replace(new RegExp("[,]", "g"), " ");
        return filterValue.includes(this.state.filter.toLowerCase());
      });
    }

    const sorted = _.sortBy(list, value =>
      value[this.props.sortBy].toLowerCase()
    );
    if (this.state.sortBy === "desc") {
      sorted.reverse();
    }
    return (
      <SearchListWrapper>
        <SearchFilterBox
          className="searchFilterBox"
          onChange={filter => this.setState({ filter })}
          onChangeDirection={sortBy => this.setState({ sortBy })}
        />
        {sorted.length === 0 && (
          <P italic textcenter>
            {this.state.filter.length > 0 ? this.props.labelNoResults : this.props.labelLoadingResults}
          </P>
        )}
        {sorted.map(data => {
          return (
            <Box mb={10} key={data.id}>
              {this.renderChild(this.props, data)}
            </Box>
          );
        })}
      </SearchListWrapper>
    );
  }
}

SearchFilterList.propTypes = {
  filterValue: PropTypes.string
};

SearchFilterList.defaultProps = {
  filterValue: "name"
};
