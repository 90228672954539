import Test from "./containers/test/Test";
import TestSelection from "./containers/testselection/TestSelection";
import Info from "./containers/info/Info";

const testRouteList = [
  { path: "/test/selection", component: TestSelection },
  { path: "/test/info/:id", component: Info },
  {
    path: "/test/test/:id/(start|counter|timer|input|rest|done)/:trailIndex",
    component: Test
  }
];

export { testRouteList };
