import React from "react";
import { inject, observer } from "mobx-react";
import StatusInfo from "components/StatusInfo/StatusInfo";
import Button from "components/Button/Button";
import BasePage from "components/BasePage/BasePage";
import Content from "components/OverlayPage/Content";
import { Box } from "grid-styled";

@inject("routing", "testStore", "uiState")
@observer
class Status extends React.Component {
  componentDidMount() {
    document.body.classList.remove("blue");
  }
  clickHandler(e) {
    e.preventDefault();
    this.props.onClick();
  }

  render() {
    const { title, text, label } = this.props;
    return (
      <BasePage>
        <Content>
          <Box>
            <StatusInfo title={title} text={text} mt={15} mb={30} />
            <Button
              autoFocus
              primary
              almostFull
              onClick={e => this.clickHandler(e)}
            >
              {label}
            </Button>
          </Box>
        </Content>
        {/* <Footer>
          <Button autoFocus primary full onClick={e => this.clickHandler(e)}>
            {label}
          </Button>
        </Footer> */}
      </BasePage>
    );
  }
}

Status.propTypes = {};
Status.defaultProps = {};

export default Status;
