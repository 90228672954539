import { injectGlobal } from 'styled-components';

injectGlobal`
    @font-face {
        font-family: 'latoblack';
        src: url('/assets/fonts/lato-black-webfont.eot');
        src: url('/assets/fonts/lato-black-webfont.eot?#iefix') format('embedded-opentype'),
             url('/assets/fonts/lato-black-webfont.woff2') format('woff2'),
             url('/assets/fonts/lato-black-webfont.woff') format('woff'),
             url('/assets/fonts/lato-black-webfont.ttf') format('truetype'),
             url('/assets/fonts/lato-black-webfont.svg#latoblack') format('svg');
        font-weight: normal;
        font-style: normal;

    }

    @font-face {
        font-family: 'latolight';
        src: url('/assets/fonts/lato-light-webfont.eot');
        src: url('/assets/fonts/lato-light-webfont.eot?#iefix') format('embedded-opentype'),
             url('/assets/fonts/lato-light-webfont.woff2') format('woff2'),
             url('/assets/fonts/lato-light-webfont.woff') format('woff'),
             url('/assets/fonts/lato-light-webfont.ttf') format('truetype'),
             url('/assets/fonts/lato-light-webfont.svg#latolight') format('svg');
        font-weight: normal;
        font-style: normal;

    }

    @font-face {
        font-family: 'latoregular';
        src: url('/assets/fonts/lato-regular-webfont.eot');
        src: url('/assets/fonts/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
             url('/assets/fonts/lato-regular-webfont.woff2') format('woff2'),
             url('/assets/fonts/lato-regular-webfont.woff') format('woff'),
             url('/assets/fonts/lato-regular-webfont.ttf') format('truetype'),
             url('/assets/fonts/lato-regular-webfont.svg#latoregular') format('svg');
        font-weight: normal;
        font-style: normal;

    }

    *{
        box-sizing:border-box;
    }
    
    html{
        overflow:hidden;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    body {
        background:white;
        overflow:hidden;
    }
    
    body.blue{
        background:#071649;
    }
    
    a,button{
        -webkit-tap-highlight-color: rgba(0,0,0,0);
    }
`;
