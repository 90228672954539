import React from "react";
import styled from "styled-components";
import { Box } from "grid-styled";

const ContentWrapper = styled(Box)`
  display: flex;
  overflow-y: auto;
  position: relative;
  justify-content: center;
`;

class LargeContent extends React.Component {
  render() {
    return (
      <ContentWrapper
        width="{1/1}"
        flex="1 1 0%"
        pl={30}
        pr={30}
        pt={20}
        is="div"
      >
        {this.props.children}
      </ContentWrapper>
    );
  }
}

export default LargeContent;
