import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Box } from "grid-styled";

import BasePage from "components/BasePage/BasePage";
import LargeContent from "components/OverlayPage/LargeContent";
import Footer from "components/OverlayPage/Footer";
import Button from "components/Button/Button";

import P from "components/P/P";
import HTMLDiv from "components/HTMLDiv/HTMLDiv";
import TestUserInfo from "components/TestUserInfo/TestUserInfo";
import messages from "./messages";
import H3 from "../../components/H3/H3";

@inject("uiState", "routing", "infoStore", "testStore")
@observer
class Info extends Component {
  componentWillMount() {
    if (!this.props.testStore.activeSporterTest) {
      this.props.routing.replace(
        `/test/selection/${this.props.uiState.sessionId}`
      );
    }
  }

  componentDidMount() {
    document.body.classList.remove("blue");
    this.getUserInfoAndLoadTestSet();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.getUserInfoAndLoadTestSet();
    }
  }

  getUserInfoAndLoadTestSet() {
    const userTestId = this.props.match.params.id;
    this.props.infoStore.loadTestSet(userTestId);
  }

  render() {
    const { infoStore, match, testStore } = this.props;
    const id = match.params.id;
    const infoModel = infoStore.infoModel;
    let sporter;
    try {
      sporter = infoModel.sporter;
    } catch (e) {}
    return (
      <React.Fragment>
        {testStore.activeSporterTest && (
          <BasePage>
            <LargeContent>
              <Box>
                {infoModel && sporter && (
                  <TestUserInfo
                    sex={sporter.gender}
                    initials={
                      sporter.lastname.charAt(0) + sporter.firstname.charAt(0)
                    }
                    name={sporter.lastname + " " + sporter.firstname}
                    age={sporter.age.toString()}
                  />
                )}

                <Button
                  // full
                  primary
                  autoFocus
                  style={{ margin: "20px auto 0", display: "block" }}
                  onClick={() => testStore.startTest(sporter)}
                >
                  <FormattedMessage {...messages.infoStartTestButton} />
                </Button>
                {infoModel && (
                  <Box mt={30}>
                    <P bold textcenter>
                      {infoModel.title}
                    </P>
                    <HTMLDiv textcenter>{infoModel.description}</HTMLDiv>

                    <Box mt={50}>
                      <H3>
                        <FormattedMessage {...messages.infoProtocolTitle} />
                      </H3>
                      <HTMLDiv>{infoModel.protocol}</HTMLDiv>
                    </Box>
                  </Box>
                )}
              </Box>
            </LargeContent>
            <Footer>
              <Button
                full
                primary
                // autoFocus
                onClick={() =>
                  testStore.startTest(sporter, `/test/test/${id}/start/0`)
                }
              >
                <FormattedMessage {...messages.infoStartTestButton} />
              </Button>
            </Footer>
          </BasePage>
        )}
      </React.Fragment>
    );
  }
}

export default injectIntl(Info);
