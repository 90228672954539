/*
 * Footer Messages
 *
 * This contains all the text for the Footer component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  modalCancelTestTitle: {
    id: "modal.cancel.test.title",
    defaultMessage: "Opgelet"
  },
  modalCancelTestDescription: {
    id: "modal.cancel.test.description",
    defaultMessage: "Opgelet wil je deze testafname verwijderen?"
  },
  tabSessionLabel: {
    id: "tab.session.label",
    defaultMessage: "Session"
  }
});
