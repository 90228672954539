import React from "react";
import H3 from "components/H3/H3";
import P from "components/P/P";
import BackButton from "components/BackButton/BackButton";
import { Box } from "grid-styled";

import { FormattedMessage } from "react-intl";
import messages from "../messages";

const SelectedHeader = ({ onClick, title, type, backMessage }) => {
  return (
    <Box mt={35}>
      <BackButton icon="back-arrow" label={backMessage} onClick={onClick} />
      <H3>{title}</H3>

      <P>
        <FormattedMessage
          {...messages.appDescriptionSubLabel}
          values={{ type: type }}
        />
      </P>
    </Box>
  );
};

export default SelectedHeader;
