/*
 * FeaturePage Messages
 *
 * This contains all the text for the FeaturePage component.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  appTitleLabel: {
    id: "app.title.label",
    defaultMessage: "Nieuwe test"
  },
  appDescriptionLabel: {
    id: "app.description.label",
    defaultMessage: "Selecteer een sporter of een test"
  },
  appDescriptionSubLabel: {
    id: "app.description.sub.label",
    defaultMessage: "Selecteer een {type}"
  },
  appBackToAllSportersButton: {
    id: "app.back.to.all.sporters.button",
    defaultMessage: "Alle sporters"
  },
  appBackToAllTestsButton: {
    id: "app.back.to.all.tests.button",
    defaultMessage: "Alle testen"
  },
  appSportersTabLabel: {
    id: "app.sporters.tab.label",
    defaultMessage: "Sporters"
  },
  appTestsTabLabel: {
    id: "app.tests.tab.label",
    defaultMessage: "Testen"
  },
  appSporterTypeLabel: {
    id: "app.sporter.type.label",
    defaultMessage: "sporter"
  },
  appTestTypeLabel: {
    id: "app.test.type.label",
    defaultMessage: "test"
  },
  appNoResultsLabel: {
    id: "app.no.results.label",
    defaultMessage: "Geen resultaten..."
  },
  appResultsLoadingLabel: {
    id: "app.loading.results.label",
    defaultMessage: "Resultaten laden..."
  },
  modalTestAlreadyFilledInTitle: {
    id: "modal.test.already.filled.in.title",
    defaultMessage: "Opgelet"
  },
  modalTestAlreadyFilledInDescription: {
    id: "modal.test.already.filled.in.description",
    defaultMessage:
      "Deze test is reeds afgelegd. Wenst u de scores te overschrijven ?"
  },
  modalTestAlreadyOpenTitle: {
    id: "modal.test.already.open.title",
    defaultMessage: "Opgelet"
  },
  modalTestAlreadyOpenDescription: {
    id: "modal.test.already.open.description",
    defaultMessage:
      "Deze sporter test relatie is alreeds toegevoegd aan de lijst"
  }
});
