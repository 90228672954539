/*
 * Footer Messages
 *
 * This contains all the text for the app.
 */
import { defineMessages } from "react-intl";

export default defineMessages({
  warningPortraitViewTitle: {
    id: "warning.portrait.view.title",
    defaultMessage: "Opgelet:"
  },
  warningPortraitViewDescription: {
    id: "warning.portrait.view.description",
    defaultMessage: "Deze applicatie kan enkel gebruikt worden in portrait mode"
  },
  appDefaultCopyOK: {
    id: "app.default.copy.ok",
    defaultMessage: "OK"
  },
  appDefaultCopyCancel: {
    id: "app.default.copy.cancel",
    defaultMessage: "Cancel"
  },
  appDefaultCopyRemove: {
    id: "app.default.copy.remove",
    defaultMessage: "Verwijderen"
  },
  appDefaultCopyClose: {
    id: "app.default.copy.close",
    defaultMessage: "Aflsuiten"
  },
  appDefaultCopyAgeFormat: {
    id: "app.default.copy.age.format",
    defaultMessage: "{age} y."
  },
  appDefaultCopyMinutesFormat: {
    id: "app.default.copy.minutes.format",
    defaultMessage: "± {min} min"
  },
  appDefaultCopyAttemptsFormat: {
    id: "app.default.copy.attempts.format",
    defaultMessage: "Resultaat poging {current}/{total}"
  }
});
