import React, { Component, Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ProgressTimer from "components/ProgressTimer/ProgressTimer";
import Button from "components/Button/Button";
import moment from "moment";

const ButtonWrapper = styled.div`
  text-align: center;
  position: relative;
  width: 100%;
  max-height: 280px;
  padding: 20px;
`;

class Chrono extends Component {
  constructor(props) {
    super(props);
    this.interval = 0;
  }

  static propTypes = {
    count: PropTypes.number,
    format: PropTypes.string,
    msg: PropTypes.string,
  };

  static defaultProps = {
    count: 0,
  };

  state = {
    count: 0,
    counting: false,
  };

  componentDidMount() {
    this.runTimer();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    clearInterval(this.pTimer);
  }

  runTimer() {
    this.startTime = Date.now();
    this.timer = setInterval(() => {
      this.updateTimer();
    }, 1000);

    this.pTimer = setInterval(() => {
      this.updateTimerP();
    }, 10);

    this.setState({
      count: 0,
      countP: 0,
      counting: true,
    });
  }

  updateTimerP() {
    this.setState((prevState) => ({
      countP: (Date.now() - this.startTime) / 1000,
    }));
  }

  updateTimer() {
    if (!this.state.counting) {
      this.props.onComplete();
      clearInterval(this.timer);
      clearInterval(this.pTimer);
    } else {
      this.setState((prevState) => ({
        count: prevState.count + 1,
      }));
    }

    if (!this.interval) {
      this.setState({
        percentage: 0,
      });
    }

    this.interval++;
  }

  onStopClickHandler() {
    clearInterval(this.timer);
    clearInterval(this.pTimer);

    if (this.state.counting) {
      this.endTime = Date.now();
    }

    this.setState({
      counting: false,
    });
    const elapsedTime = this.endTime - this.startTime;
    this.props.onStopClicked(elapsedTime);
  }

  onResetHandler(e) {
    const { onReset } = this.props;
    e.preventDefault();
    clearInterval(this.timer);
    clearInterval(this.pTimer);
    this.setState({
      count: 0,
      countP: 0,
    });
    onReset && onReset();
  }

  render() {
    const { count, countP } = this.state;
    const unixTimeP = moment.unix(countP);
    const time = unixTimeP.format("mm:ss:SS").toString();

    const unixTime = moment.unix(count);
    const seconds = unixTime.seconds();
    const percentage = seconds / 0.6;
    return (
      <Fragment>
        <ProgressTimer
          msg={this.props.msg}
          subMsg={this.props.subMsg}
          percentage={percentage}
          time={time}
          onClick={() => this.onStopClickHandler()}
        />

        {
          <ButtonWrapper>
            <Button secondary onClick={(e) => this.onResetHandler(e)}>
              Reset
            </Button>
          </ButtonWrapper>
        }
      </Fragment>
    );
  }
}

export default Chrono;
