import React, { Component } from 'react';
import styled from 'styled-components';

const TabBar = styled.div`
  button {
    margin-right: 25px;
  }
`;

class TabBarList extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: props.activeIndex };
  }

  selectTabIndex(activeIndex) {
    this.setState({ activeIndex });
    this.props.onChange(activeIndex);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ activeIndex: nextProps.activeIndex });
  }

  render() {
    const children = React.Children.map(this.props.children, (child, i) => {
      let childProps = {
        onClick: () => {
          this.selectTabIndex(i);
        }
      };
      if (i === this.state.activeIndex) {
        childProps.active = 'active';
      }
      return React.cloneElement(child, childProps);
    });
    return <TabBar className="class-name">{children}</TabBar>;
  }
}
export default TabBarList;
