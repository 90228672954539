import React from "react";
import { injectIntl } from "react-intl";

import ListLabelButton from "components/ListLabelButton/ListLabelButton";
import ListIconButton from "components/ListIconButton/ListIconButton";
import messages from "../../../messages";

const ListLabelTestWrapperSelected = ({
  data,
  onClick,
  selectedSporter,
  sporterTestList,
  intl
}) => {
  const label = data.title;
  const info = intl.formatMessage(messages.appDefaultCopyMinutesFormat, {
    min: data.duration
  });
  let statusLabel = `${data.completed}/${data.total}`;

  const isCompleted = selectedSporter.tests.find(testId => {
    return testId === data.id;
  });

  const isBusy = sporterTestList.find(sporterTest => {
    return (
      sporterTest.test.id === data.id &&
      sporterTest.sporter.id === selectedSporter.id
    );
  });

  if (isBusy) {
    statusLabel = `${isBusy.testProgress.trialIndex}/${
      isBusy.testProgress.trials
    }`;
  }

  return (
    <div>
      {isCompleted && !isBusy && (
        <ListIconButton
          gray
          label={label}
          statusColor="green"
          statusIcon="check"
          extraLabel={data.lastname}
          info={info}
          onClick={() => onClick(data.id, true)}
        />
      )}
      {isBusy && (
        <ListLabelButton
          gray
          label={label}
          statusColor="blue"
          statusLabel={statusLabel}
          extraLabel={data.lastname}
          info={info}
          onClick={() => onClick(data.id)}
        />
      )}

      {!isCompleted && !isBusy && (
        <ListIconButton
          gray
          label={label}
          statusColor="orange"
          statusIcon="clipboard"
          extraLabel={data.lastname}
          info={info}
          onClick={() => onClick(data.id)}
        />
      )}
    </div>
  );
};

export default injectIntl(ListLabelTestWrapperSelected);
