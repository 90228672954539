import { observable, action } from "mobx";
import { persist } from "mobx-persist";
import SporterModel from "containers/testselection/store/model/SporterModel";
import TestModel from "containers/testselection/store/model/TestModel";
import TestProgressModel from "./TestProgressModel";
import flattenArray from "utils/flattenArray";

export default class SporterTestModel {
  @persist
  @observable
  id;

  @persist("object", SporterModel)
  @observable
  sporter;

  @persist("object", TestModel)
  @observable
  test;

  @persist("object", TestProgressModel)
  @observable
  testProgress;

  @persist("object")
  data;

  constructor(id, sporter, test, trials) {
    this.id = id;
    this.sporter = sporter;
    this.test = test;
    this.testProgress = new TestProgressModel(trials);
  }

  @action
  createTestProgress(trials) {
    this.testProgress = new TestProgressModel(trials);
  }

  @action
  setProgress(data) {
    this.testProgress.setResult(data);
  }

  getSaveData() {
    const resultSet = this.testProgress.getResult();
    const lateralities = this.data.test.testItems[0].sides;

    // TODO change this to enable multiple lateralities
    const lateralityValue = flattenArray(Object.entries(lateralities))[0];

    const data = {};
    //const sporterId = this.sporter.id;
    const testId = this.test.id;
    const resultSetKey = resultSet.key;
    //data[sporterId] = {};
    data[testId] = {};
    data[testId][resultSetKey] = {};
    data[testId][resultSetKey][lateralityValue] = resultSet.resultSet;

    return {
      sporter: this.sporter,
      data,
    };
  }

  toJS() {
    return {
      id: this.id,
      sporter: this.sporter.toJS(),
      test: this.test.toJS(),
      testProgress: this.testProgress.toJs(),
    };
  }
}
