import React, { Fragment } from "react";
import { injectIntl } from "react-intl";
import messages from "./messages";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "underscore";

import Button from "../Button/Button";
import Chrono from "./Chrono";

const FormItemWrapper = styled.div`
  text-align: center;
  padding-bottom: 15px;
  position: relative;

  input {
    position: relative;
    z-index: 1;
    background: none;
  }
`;

const Circle = styled.a`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 100%;
  width: 280px;
  height: 280px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  text-align: center;

  p {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    margin: 0;
    cursor: pointer;

    span {
      display: block;
      font-weight: 900;
      font-family: ${props => props.theme.fonts.fontface};
      line-height: 1em;
      margin: 0;
      color: ${props => props.theme.colors.nightblue};
      width: 100%;

      &.start-button-text1 {
        font-size: 50px;
        letter-spacing: 0.4px;
        line-height: 1.2;
        color: ${props => props.theme.colors.blue};
        transition: color 0.2s ease-in-out;
      }

      &.start-button-text2 {
        font-size: 20px;
        line-height: 1.2;
        letter-spacing: 0.2px;
        color: ${props => props.theme.colors.blue};
        transition: color 0.2s ease-in-out;
      }
    }

    &:hover {
      span {
        &.start-button-text1 {
          color: ${props => props.theme.colors.nightblue};
        }

        &.start-button-text2 {
          color: ${props => props.theme.colors.nightblue};
        }
      }
    }
  }
`;

class InputDuration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timerStart: true,
      timerRunning: false,
      timerStopped: false,
      percentage: 0,
      value: props.value ? props.value : "",
      valid: false,
      submittable: false
    };
  }

  onStartClickHandler() {
    this.setState({
      timerStart: false,
      timerRunning: true
    });
  }

  onClickHandler(e) {
    const submittable = this.validateInput(this.state.value);

    if (!submittable) {
      e.preventDefault();
    }
  }

  handleTimerComplete(e) {}

  onStopClicked(count) {
    const value = this.props.unit === "min" ? count / 60000 : count / 1000;
    this.setState({
      value,
      timerStart: false,
      timerRunning: false,
      timerStopped: true
    });
  }

  validateInput(value) {
    let valid;
    let submittable;
    if (!_.isNumber(value)) {
      this.setState({
        value: "",
        valid: false
      });
      return;
    }

    valid = value && value >= this.props.min && value <= this.props.max;

    submittable = value && value >= 0;

    this.setState({
      value: value,
      valid: valid,
      submittable: submittable
    });

    return submittable;
  }
  render() {
    const { timerStart, timerRunning, timerStopped, value } = this.state;
    const { name, intl } = this.props;

    console.log('chrono', timerRunning, timerStopped);
    return (
      <FormItemWrapper>
        {timerStart && (
          <Circle onClick={e => this.onStartClickHandler(e)}>
            <p className="start-button">
              <span className="start-button-text1">
                {intl.formatMessage(messages.start)}
              </span>
              <span className="start-button-text2">
                {intl.formatMessage(messages.timer)}
              </span>
            </p>
          </Circle>
        )}
        {(timerRunning || timerStopped) && (
          <Fragment>
            <Chrono
              count={0}
              msg={timerRunning ? intl.formatMessage(messages.stop) : ""}
              subMsg={timerStopped ? intl.formatMessage(messages.endTimer) : ""}
              skipBtnLabel={intl.formatMessage(messages.skip)}
              onComplete={() => this.handleTimerComplete()}
              onStopClicked={count => this.onStopClicked(count)}
              onReset={() =>
                this.setState({
                  timerStopped: false,
                  timerRunning: false,
                  timerStart: true
                })
              }
            />
            <input type="hidden" name={name} value={value} />
          </Fragment>
        )}
        {timerStopped && (
          <Button full primary inverted onClick={e => this.onClickHandler(e)}>
            {intl.formatMessage(messages.saveTimer)}
          </Button>
        )}
      </FormItemWrapper>
    );
  }
}

InputDuration.propTypes = {
  placeholder: PropTypes.string,
  valid: PropTypes.bool
};

export default injectIntl(InputDuration);
