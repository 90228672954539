import React from "react";
import styled from "styled-components";
import { Box } from "grid-styled";

const ContentWrapper = styled(Box)`
  max-width: 600px;
  overflow-y: auto;
  position: relative;
  align-self: center;
  width: 100%;
`;

class Content extends React.Component {
  render() {
    return (
      <ContentWrapper
        width="{1/1}"
        flex="1 1 0%"
        pl={20}
        pr={20}
        pb={20}
        is="div"
      >
        {this.props.children}
      </ContentWrapper>
    );
  }
}

export default Content;
