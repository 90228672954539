/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
const { addLocaleData } = require("react-intl");
const enLocaleData = require("react-intl/locale-data/en");
const nlLocaleData = require("react-intl/locale-data/nl");
const { DEFAULT_LOCALE, NL_TRANSLATION_FILE } = require("./constants");

const enTranslationMessages = require("./translations/en.json");
const nlTranslationMessages = require(`./translations/${NL_TRANSLATION_FILE}`);

export const appLocales = [
  "nl-be",
  "nl-nl",
  "en-sg",
  "en-be",
  "en-nl",
  "nl-sg",
  "pt-pt",
  "fr-fr",
  "es-es",
  'fi-fi',
  'el-gr',
  'it-it',
  'pl-pl',
  'de-de',
];
export const realLocales = ["en", "nl"];

addLocaleData(enLocaleData);
addLocaleData(nlLocaleData);

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, nlTranslationMessages)
      : {};
  return Object.keys(messages).reduce((formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  }, {});
};

export const translationMessages = {
  en: formatTranslationMessages("en", enTranslationMessages),
  nl: formatTranslationMessages("nl", nlTranslationMessages),
};
