import React from "react";
import { injectIntl } from "react-intl";

import ListLabelButton from "components/ListLabelButton/ListLabelButton";
import ListIconButton from "components/ListIconButton/ListIconButton";
import { getStatusColor } from "../utils";
import messages from "../../../messages";

const ListLabelTestWrapper = ({ data, onClick, intl }) => {
  const label = data.title;
  const info = intl.formatMessage(messages.appDefaultCopyMinutesFormat, {
    min: data.duration
  });

  const statusLabel = `${data.completed}/${data.total}`;
  let statusColor = getStatusColor(data);
  const isCompleted = data.completed === data.total;

  return (
    <div>
      {isCompleted ? (
        <ListIconButton
          gray
          label={label}
          statusColor={statusColor}
          statusIcon="check"
          extraLabel={data.lastname}
          info={info}
          onClick={() => onClick(data.id)}
        />
      ) : (
        <ListLabelButton
          gray
          label={label}
          statusColor={statusColor}
          statusLabel={statusLabel}
          extraLabel={data.lastname}
          info={info}
          onClick={() => onClick(data.id)}
        />
      )}
    </div>
  );
};

export default injectIntl(ListLabelTestWrapper);
