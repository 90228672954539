import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from 'grid-styled';

import Input from '../Input/Input';
import FilterButton from '../FilterButton/FilterButton';

const SeachBlockWrapper = styled(Flex)`margin-bottom: 15px;`;
const InputWrapper = styled(Input)`
  display: flex;
  flex: 1 1 auto;
  margin-right: 15px;
`;

class SearchFilterBox extends React.Component {
  render() {
    const { onChange, onChangeDirection } = this.props;
    return (
      <SeachBlockWrapper>
        <InputWrapper search onChange={event => onChange(event.target.value)} />
        <FilterButton
          small
          direction="desc"
          onChangeDirection={onChangeDirection}
        />
      </SeachBlockWrapper>
    );
  }
}

SearchFilterBox.propTypes = {
  onChangeDirection: PropTypes.func,
  onChange: PropTypes.func
};

SearchFilterBox.defaultProps = {
  onChangeDirection: direction => console.log('onChangeDirection', direction),
  onChange: event => console.log('SearchFilterBox onChange')
};

export default SearchFilterBox;
