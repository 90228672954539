import React from "react";
import styled from "styled-components";
import { Flex } from "grid-styled";
import PropTypes from "prop-types";

const PageWrapper = styled(Flex)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => props.theme.zIndex.overlayPage};

  &.white {
    background: ${props => props.theme.colors.white};
  }

  &.blue {
    background: ${props => props.theme.colors.nightblue};
  }
`;

class OverlayPage extends React.Component {
  render() {
    const { color } = this.props;

    return (
      <PageWrapper className={color} flexDirection="column">
        {this.props.children}
      </PageWrapper>
    );
  }
}

OverlayPage.propTypes = {
  color: PropTypes.oneOf(["blue", "white"])
};

export default OverlayPage;
