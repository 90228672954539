import styled, { css } from 'styled-components';

const H2 = styled.h2`
  display: block;
  font-weight: 600;
  font-family: ${props => props.theme.fonts.fontfaceBold};
  letter-spacing: 0.4px;
  font-size: ${props => props.theme.fonts.sizeH2};
  line-height: 1em;
  margin: 0;
  color: ${props => props.theme.colors.nightblue};

  ${props =>
    props.upper &&
    css`
      text-transform: uppercase;
    `}

  ${props =>
    props.textcenter &&
    css`
      text-align: center;
    `}
  
  ${props =>
    props.inverted &&
    css`
      color: ${props => props.theme.colors.white};
    `}
  
  ${props =>
    props.highlighted &&
    css`
      color: ${props => props.theme.colors.blue};
    `};

  ${props =>
    props.buttonStop &&
    css`
      cursor: pointer;
      /* transition: color 0.2s ease-in-out;

      &:hover {
        color: ${props => props.theme.colors.blue};
      } */
    `}
`;

export default H2;
