import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { inject, observer } from "mobx-react";

import TestersBar from "components/TestersBar/TestersBar";
import TestersButton from "components/TestersButton/TestersButton";
import messages from "./messages";
import defaultMessages from "../../messages";

@injectIntl
@inject("uiState", "routing", "testStore", "testSelectionStore")
@observer
class TestList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null
    };
  }
  componentDidMount() {
    document.body.classList.remove("blue");
  }

  deleteHandler = sporterTest => {
    this.props.uiState.createAlert({
      title: this.props.intl.formatMessage(messages.modalCancelTestTitle),
      message: this.props.intl.formatMessage(
        messages.modalCancelTestDescription
      ),
      ok: this.props.intl.formatMessage(defaultMessages.appDefaultCopyRemove),
      okHandler: () => {
        this.props.testStore.deleteById(sporterTest.id);
      },
      cancel: this.props.intl.formatMessage(
        defaultMessages.appDefaultCopyCancel
      )
    });
  };

  render() {
    const { testStore, routing, uiState, intl } = this.props;
    const sporterTestList = testStore.sporterTestList;

    const activeSporterId = testStore.activeSporterTest
      ? testStore.activeSporterTest.id
      : 0;

    return (
      <TestersBar
        label={intl.formatMessage(messages.tabSessionLabel)}
        addHandler={() => {
          routing.push(`/test/selection/${uiState.sessionId}`);
        }}
        showArrowUnderAdd={!activeSporterId}
      >
        {sporterTestList.length > 0 &&
          sporterTestList.map(sporterTest => (
            <TestersButton
              key={sporterTest.id}
              active={activeSporterId === sporterTest.id}
              onClick={() => testStore.setActiveTestById(sporterTest.id)}
              deleteHandler={() => this.deleteHandler(sporterTest)}
              label={`${sporterTest.sporter.firstname} ${
                sporterTest.sporter.lastname
              } - ${sporterTest.test.title.substr(0, 10)}...`}
              info={`${sporterTest.testProgress.trialIndex}/${
                sporterTest.testProgress.trials
              }`}
            />
          ))}
      </TestersBar>
    );
  }
}

export default injectIntl(TestList);
