export default class AlertModel {
  title;
  message;
  /**
   * the alertmodel is responsible for showing the alert box
   * depending on the optional parameters there will be title message
   * @param {string} title 
   * @param {string} message 
   * @param {function} okHandler is the function that needs to be executed when ok is pushed
   * @param {boolean} cancelHandler is the function that needs to be executed when cancel is pushed
   */
  constructor(title, message, ok, okHandler, cancel, cancelHandler) {
    this.title = title;
    this.message = message;
    this.ok = ok;
    this.okHandler = okHandler;
    this.cancel = cancel;
    this.cancelHandler = cancelHandler;
  }
}
