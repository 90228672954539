import { observable, action, reaction, runInAction } from "mobx";
import { persist } from "mobx-persist";
import _ from "underscore";
import remotedev from "mobx-remotedev/lib/dev";

import SporterModel from "./model/SporterModel";
import TestModel from "./model/TestModel";
import SporterTestModel from "containers/test/store/model/SporterTestModel";
import { SPORTER_STATE, TEST_STATE } from "../constants";
import { setTests, loadTestSelectionData } from "../../../utils/api";
import messages from "../messages";
import defaultMessages from "../../../messages";
import { MessageType, StorageType } from "../../../enums";

const matchSportersAndTestsWithResults = (sporters, tests, results) => {
  for (const sporterResult in results) {
    const athlete = sporters.find((sporter) => sporter.id === sporterResult);

    if (!athlete) {
      return;
    }

    athlete.tests = [];

    for (const testResult in results[sporterResult]) {
      athlete.tests.push(testResult);

      const testSearch = tests.find((test) => test.id === testResult);

      if (testSearch) {
        testSearch.sporters.push(sporterResult);
      }
    }
  }
};
class TestSelectionStore {
  firstDisposer;
  secondDisposer;
  thirdDisposer;

  /**
   * state used to update the view to the correct selected tab index
   * SPORTER_STATE = 0 is the first tab
   * TEST_STATE = 1 is the second tab
   */
  @observable
  selectedState = SPORTER_STATE;

  @observable
  sporters = [];

  @observable
  tests = [];

  @persist("object", SporterModel)
  @observable
  selectedSporter;

  @persist("object", SporterTestModel)
  @observable
  selectedTest;

  @persist("object")
  @observable
  resetStateData = {};

  constructor(routerStore, uiState, testStore) {
    this.routerStore = routerStore;
    this.uiState = uiState;
    this.testStore = testStore;
    // this.loadTestSelectionData();
    window.addEventListener("message", this.receiveData, false);
  }

  injectIntl = (intl) => {
    this.intl = intl;
  };

  receiveData = (event) => {
    let message;
    try {
      message = JSON.parse(event.data);
    } catch (er) {}

    if (message && message.type === MessageType.INIT_DATA) {
      if (message.data.config && message.data.config.tests) {
        setTests(message.data.config);
      }
      if (message.data.sessionName) {
        this.uiState.setSessionName(message.data.sessionName);
      }
      if (message.data.name) {
        this.uiState.setHan(message.data.name.toLowerCase() === "han");
      }
      this.loadTestSelectionData(message.data.sporters, message.data.testData);
    } else if (
      message &&
      message.type === MessageType.TEST_RECEIVED_SESSION_ID
    ) {
      sessionStorage.setItem(
        StorageType.ACTIVE_SESSION_ID,
        message.data.activeTestSessionId
      );
    }
  };

  createReactions() {
    /**
     * reaction when we have a sporter selection but no test selected
     * the selected state should change to select test state
     * is reflected in the view
     *
     */
    this.firstDisposer = reaction(
      () => this.selectedSporter && !this.selectedTest,
      (sporter) => {
        if (sporter) {
          this.selectedState = TEST_STATE;
          if (this.selectedSporter) {
            this.resetStateData = {
              state: TEST_STATE,
              sporter: this.selectedSporter.id,
            };
          }
        }
      }
    );

    /**
     * reaction when we have a test selection but no sporter selected
     * the selected state should change to the select sporter state
     * is reflected in the view
     *
     */
    this.secondDisposer = reaction(
      () => this.selectedTest && !this.selectedSporter,
      (test) => {
        if (test) {
          this.selectedState = SPORTER_STATE;
          if (this.selectedTest) {
            this.resetStateData = {
              state: SPORTER_STATE,
              test: this.selectedTest.id,
            };
          }
        }
      }
    );

    /**
     * reaction to when both selectedTest and selected sporter are selected
     * we redirect to the test start and we add it
     */
    this.thirdDisposer = reaction(
      () => this.selectedTest && this.selectedSporter,
      (success) => {
        if (success) {
          const sporterTest = this.testStore.addSporterTest(
            this.selectedSporter,
            this.selectedTest
          );

          if (sporterTest) {
            const testId = sporterTest.id;
            this.routerStore.push(`/test/info/${testId}`);
          } else {
            this.uiState.createAlert({
              title: this.intl.formatMessage(
                messages.modalTestAlreadyOpenTitle
              ),
              message: this.intl.formatMessage(
                messages.modalTestAlreadyOpenDescription
              ),
              ok: this.intl.formatMessage(defaultMessages.appDefaultCopyOK),
            });
            this.resetLastAdded();
          }
        }
      }
    );
  }

  removeReactions() {
    this.firstDisposer();
    this.secondDisposer();
    this.thirdDisposer();
  }

  @action
  initializeApp() {
    const message = {
      type: MessageType.IFRAME_READY,
    };
    window.parent.postMessage(JSON.stringify(message), "*");
  }

  @action
  loadTestSelectionData(initSporters, initTestData) {
    /* this.sporters = [];
    this.tests = [];
    this.resetState(SPORTER_STATE);
    this.testStore.sporterTestList = []; */

    const testSelectionData = loadTestSelectionData(initSporters, initTestData);

    const sporters = testSelectionData.athletes.map((item) =>
      SporterModel.fromJS(item, testSelectionData.test_count)
    );

    const tests = testSelectionData.tests.map((item) =>
      TestModel.fromJS(
        item,
        testSelectionData.athlete_count,
        testSelectionData.testset
      )
    );

    matchSportersAndTestsWithResults(
      sporters,
      tests,
      testSelectionData.results
    );

    // TODO BUG na binnenkomen van updated data van sportkompas
    runInAction(() => {
      this.removeReactions();
      this.sporters = sporters;
      if (this.selectedSporter) {
        this.selectSporterById(this.selectedSporter.id);
      }
      this.tests = tests;
      this.createReactions();
    });
  }

  @action
  selectSporterById(id) {
    const selectedSporter = _.findWhere(this.sporters, { id });
    if (selectedSporter) {
      this.selectedSporter = selectedSporter;
    }
  }

  @action
  setSelectedState(index) {
    this.selectedState = index;
  }

  @action
  selectTestById(id) {
    const selectedTest = _.findWhere(this.tests, { id });
    if (selectedTest) {
      this.selectedTest = selectedTest;
    }
  }

  @action
  resetState(selectedState) {
    this.resetStateData = {};
    this.selectedSporter = undefined;
    this.selectedTest = undefined;
    this.selectedState = selectedState;
  }

  /**
   * responsible for restoring state to the first selected state selection
   * so if we have selected a test and and sporter and we come back to the
   * test selection view the first selected value is stored
   */
  @action
  restoreState() {
    if (this.resetStateData.sporter && this.resetStateData.state) {
      this.selectedTest = undefined;
      this.selectedState = this.resetStateData.state;
      this.selectSporterById(this.resetStateData.sporter);
    } else if (this.resetStateData.test && this.resetStateData.state === 0) {
      this.selectedSporter = undefined;
      this.selectedState = this.resetStateData.state;
      this.selectTestById(this.resetStateData.test);
    }
  }

  @action
  resetLastAdded(selectedState) {
    if (this.selectedState === SPORTER_STATE) {
      this.selectedSporter = undefined;
    } else {
      this.selectedTest = undefined;
    }
  }
}

export default remotedev(TestSelectionStore, { global: true });
