import React from 'react';
import styled, { css } from 'styled-components';
import Loader from '../Loader/Loader';

const LoaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  z-index: ${props => props.theme.zIndex.loaderOverlay};
  align-items: center;
  text-align: center;
  justify-content: center;

  ${props =>
    props.white && css`background: ${props => props.theme.colors.white50};`};
`;

class LoaderOverlay extends React.Component {
  render() {
    return (
      <LoaderWrapper {...this.props}>
        <Loader />
      </LoaderWrapper>
    );
  }
}

export default LoaderOverlay;
