import React, { Component } from "react";
import PropTypes from "prop-types";
import OverlayPage from "components/OverlayPage/OverlayPage";
import Content from "components/OverlayPage/Content";
import Footer from "components/OverlayPage/Footer";
import H1 from "components/H1/H1";
import Tock from "tocktimer";
import { Howl } from "howler";

class Counter extends Component {
  static contextTypes = {
    count: PropTypes.number,
  };

  static propTypes = {
    count: PropTypes.number,
  };

  static defaultProps = {
    count: 0,
    beep: new Howl({ src: ["/assets/sound/beep.mp3"] }),
    longBeep: new Howl({ src: ["/assets/sound/long_beep.mp3"] }),
  };

  state = {
    count: this.props.count,
    counting: true,
  };

  componentDidMount() {
    this.runTimer();
  }

  componentWillUnmount() {
    this.timer.stop();
  }

  runTimer() {
    this.timer.start(this.state.count * 1000);
  }

  timer = new Tock({
    countdown: true,
    interval: 1000,
    callback: this.onTock.bind(this),
    complete: this.onCompleted.bind(this),
  });

  onTock() {
    if (this.state.count === 1) {
      this.props.longBeep.play();
      this.setState({
        counting: false,
      });
    } else if (this.state.count > 1) {
      this.props.beep.play();
      this.setState({ counting: true });
    }
    this.setState({ count: this.state.count - 1 });
  }

  onCompleted() {
    this.props.onComplete();
  }

  render() {
    const counting = this.state.counting;
    const header = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {
        color: counting ? "blue" : "white",
      })
    );

    return (
      <OverlayPage color={counting ? "blue" : "white"}>
        {header}
        <Content>
          {counting ? (
            <H1>{this.state.count}</H1>
          ) : (
            <H1 highlighted>{this.props.msg}</H1>
          )}
        </Content>
        <Footer />
      </OverlayPage>
    );
  }
}

export default Counter;
