import React from 'react';
import styled from 'styled-components';
import textStyle from '../P/textStyle';

const Div = styled.div`
  ${textStyle};

  h1 {
    line-height: 1em;
  }

  img {
    width: 100%;
    height: auto;
  }

  ol,
  ul {
    margin-left: 0;
    padding-left: 1em;
  }

  blockquote {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;

class HTMLDiv extends React.Component {
  render() {
    const { children, ...rest } = this.props;
    return <Div {...rest} dangerouslySetInnerHTML={{ __html: children }} />;
  }
}

export default HTMLDiv;
