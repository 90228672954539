import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import H1 from "../H1/H1";
import H2 from "../H2/H2";
import H3 from "../H3/H3";
import CircleWrapper from "./CircleWrapper";

const ProgressTimerWrapper = styled.div`
  text-align: center;
  position: relative;
  width: 100%;
  max-width: 280px;
  max-height: 280px;
  padding: 20px;

  h1 {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  h2 {
    font-size: 30pt;
    position: absolute;
    left: 50%;
    /* top: 30%; */
    transform: translate(-50%, -50%);
    top: calc(50% - 20px);
  }

  h3 {
    position: absolute;
    left: 50%;
    /* bottom: 20%; */
    transform: translate(-50%, -50%);
    top: calc(50% + 30px);
  }
`;

class ProgressTimer extends React.Component {
  render() {
    const {
      percentage,
      time,
      count,
      duration = 1,
      msg,
      subMsg,
      onClick
    } = this.props;
    return (
      <ProgressTimerWrapper onClick={onClick}>
        {time &&
          ((msg && (
            <React.Fragment>
              <H2 upper inverted textcenter buttonStop>
                {msg}
              </H2>
              <H3 timer inverted>
                {time}
              </H3>
            </React.Fragment>
          )) ||
            (subMsg && (
              <React.Fragment>
                <H2 upper inverted textcenter>
                  {time}
                </H2>
                <H3 inverted upper>
                  {subMsg}
                </H3>
              </React.Fragment>
            )))}

        {count && <H1 small>{count}</H1>}

        <CircleWrapper duration={duration} percent={percentage} />
      </ProgressTimerWrapper>
    );
  }
}

ProgressTimer.propTypes = {
  percentage: PropTypes.number,
  time: PropTypes.string
};

export default ProgressTimer;
