import React, { Fragment } from "react";
import { inject, observer } from "mobx-react";
import Header from "./Header";
import TestList from "../../containers/testlist/TestList";

@inject("uiState")
@observer
export default class HeaderWrapper extends React.Component {
  render() {
    return (
      <Fragment>
          <Header>
            <TestList />
          </Header>
      </Fragment>
    );
  }
}
