import { observable, action } from 'mobx';

export default class InfoModel {
  @observable sporter;
  @observable title;
  @observable description;
  @observable protocol;

  constructor(sporter, title, description, protocol) {
    this.sporter = sporter;
    this.title = title;
    this.description = description;
    this.protocol = protocol;
  }

  @action
  updateModel(sporter, title, description, protocol) {
    this.sporter = sporter;
    this.title = title;
    this.description = description;
    this.protocol = protocol;
    return this;
  }

  toJS() {
    return {
      sporter: this.sporter,
      title: this.title,
      description: this.description,
      protocol: this.protocol
    };
  }

  static fromJS(sporter, title, description, protocol) {
    return new InfoModel(sporter, title, description, protocol);
  }
}
