import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import Icon from "../Icon/Icon";
import media from "styled-media-query";

import {inject} from "mobx-react";

const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 110px;
  background-color: ${props => props.theme.colors.nightblue};
`;

/*const NavigationButton = styled(Button)`
  padding: 15px;
  position: relative;
  z-index: ${props => props.theme.zIndex.header};

  svg {
    width: 27px;
    height: 27px;
  }
`;*/

const LogoWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 14px;
  width: 100%;
  text-align: center;

  svg {
    width: 96px;
    height: 30px;
    max-width: 100%;

    /* ${media.greaterThan("small")`
      max-width: 100%;
      width: 220px;
    `}; */
  }

  ${props =>
    props.sizeBig &&
    css`
      svg {
        width: 220px;
        height: 50px;
      }
    `};
`;
const ChildrenWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: ${props => props.theme.colors.white};
`;

@inject("uiState")
class Header extends React.Component {
  render() {
    const {
      uiState: { isHan }
    } = this.props;

    let logo;
    if(isHan) {
      logo = 'logo-han';
    } else {
      const location = window.location.hostname;
      if (location.includes('hylyght')) {
        logo = 'logo-hylyght';
      }
      if (location.includes('sportamundi') || location.includes('sportkompas')) {
        logo = 'logo-sportamundi';
      }
      if (location.includes('fundis')) {
        logo = 'logo-fundis';
      }
    }

    console.log('LOGO', logo);
    return (
      <HeaderWrapper>
          <LogoWrapper sizeBig>
            {logo && <Icon id={logo} />}
          </LogoWrapper>
        <ChildrenWrapper>{this.props.children}</ChildrenWrapper>
      </HeaderWrapper>
    );
  }
}

Header.propTypes = {
  menuClick: PropTypes.func
};

export default Header;
