import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import Button from '../Button/Button';
import textStyle from '../P/textStyle';

const iconColors = ['blue', 'orange', 'green'];
const ButtonWrapper = styled(Button)`
  background: ${props => props.theme.colors.white};
  display: flex;
  justify-content: flex-start;
  height: 50px;
  align-items: center;
  ${props => props.gray && css`background: #f4f5f7;`};
`;
const ButtonText = styled.span`
  ${textStyle} flex-grow: 1;
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 50px;
  text-align: left;

  span {
    font-family: ${props => props.theme.fonts.fontface};
  }
`;
const InfoText = styled.span`
  ${textStyle};
  display: flex;
  align-items: center;
  padding: 5px 20px;
`;
const StatusWrapper = styled.div`
  background: ${props => props.theme.colors[props.statusColor]};
  width: 50px;
  height: 50px;
  flex: 0 0 50px;
  display: flex;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;

  svg {
    width: 100%;
    height: 30px;
  }
`;
const StatusLabel = styled.span`
  ${textStyle} font-size: ${props => props.theme.fonts.sizeSuperSmall};
  width: 100%;
  text-align: center;
`;

class ListLabelButton extends React.Component {
  render() {
    const {
      statusColor,
      statusLabel,
      label,
      extraLabel,
      info,
      onClick
    } = this.props;

    return (
      <ButtonWrapper rounded full onClick={onClick} {...this.props}>
        <StatusWrapper statusColor={statusColor}>
          <StatusLabel inverted>{statusLabel}</StatusLabel>
        </StatusWrapper>
        <ButtonText bold>
          {label}
          <span>&nbsp;{extraLabel}</span>
        </ButtonText>

        {info && <InfoText italic>{info}</InfoText>}
      </ButtonWrapper>
    );
  }
}

ListLabelButton.propTypes = {
  onClick: PropTypes.func,
  statusColor: PropTypes.oneOf(iconColors),
  icon: PropTypes.string,
  label: PropTypes.string
};

ListLabelButton.defaultProps = {
  statusColor: iconColors[0]
};

export default ListLabelButton;
