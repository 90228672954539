import React from 'react';
import styled from 'styled-components';
import { Box } from 'grid-styled';

const HeaderWrapper = styled(Box)`
  width: 100%;
  align-self: flex-start;
  overflow: hidden;
`;

class Header extends React.Component {
  render() {
    var flexSizeInner = '0 0 110px';

    if (typeof this.props.size !== 'undefined') {
      flexSizeInner = '0 0 60px';
    }
    const flexSize = flexSizeInner;

    return (
      <HeaderWrapper width="{1/1}" flex={flexSize} is="header">
        {this.props.children}
      </HeaderWrapper>
    );
  }
}

export default Header;
